.ax-fieldset {
    // for wrapping labels and their inputs
    display: flex;
    flex-direction: column;
    border: none;
    margin: 0;
    padding: 0;

    & legend {
        font-family: $barlow-font-stack;
        font-size: toRem(22);
        margin-bottom: toRem(16);
        margin-left: -10px;
        padding: 0 10px;

        // Required
        &[required],
        &.required,
        &:required {
            &::after {
                content: ' (required)';
                font-weight: 400;
                font-style: italic;
            }
        }
    }

    // Replicate states using class on wrapper
    &--horizontal {
        flex-direction: row;
    }
}