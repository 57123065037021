// For bootstrap overrides see src/styles/scss/vendor/_bootstrap-config.scss
.ax-layout {
    @import "~bootstrap/scss/containers";
    @import "~bootstrap/scss/grid";

    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl,
    .container-fluid,
    .row,
    .col,
    [class*='col-'] {
        box-sizing: border-box;
    }
}
