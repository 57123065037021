.powerbar-sliding-panel {
    position: fixed;
    left: 0;
    top: 55px; // Match searchbar height
    height: auto;
    background: var(--class-deep-sea);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 4; // 1 less than searchbar but higher than navbar
    padding: 40px 12px 24px;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 0px;
    width: auto; // Can pass a custom width using prop

    padding: 12px 20px 2rem 20px;


    &.slide-enter-active,
    &.slide-leave-active {
        transition: transform 0.2s ease;
    }

    &.slide-enter,
    &.slide-enter-from,
    &.slide-leave-to {
        transform: translateX(-100%);
        transition: all 150ms ease-in 0s
    }

    &--enter-right {
        border-radius: 0px 0px 0px 8px;
        left: unset;
        right: 0;

        &.slide-enter,
        &.slide-leave-to {
            transform: translateX(100%);
        }
    }

    &--is-full-height {
        height: calc(100vh - 55px);
    }
}