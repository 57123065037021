.ax-toggle-button-vertical {
    flex-direction: column;
    width: inherit;
}

.ax-toggle-button-horizontal {
    flex-direction: row;
    width: 100%;
}

.ax-toggle-button {
    background-color: var(--int-teal-tint-20);
    border-radius: 4px;
    padding: 2px;
    display: flex;
    justify-content: space-evenly;

    .ax-toggle-button-label {
        position: relative;
        // width: inherit;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #515B6D;
        margin: 2px;

        &:hover {
            color: var(--int-teal);
        }

        input[type="radio"] {
            position: absolute;
            width: 100%;
            height: 100%;
            margin: 0;
            border-radius: 2px;
            -webkit-appearance: none; /*to disable the default appearance of radio button*/
            -moz-appearance: none;

            &:focus { 
                outline: none; 
                border: 1px solid var(--int-teal);
            }

            &:checked { 
                background-color: var(--int-white);

                &:focus { 
                    border: none;
                }
            }

            &:checked + span {
                background-color: inherit;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: var(--int-dark-grey);
            }
        }

        span {
            position: relative;
            width: inherit;
            height: inherit;
            top: 0;
            display: flex;
            justify-content: center;
            font-family: $barlow-font-stack;
            font-size: 14px;
            line-height: 17px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}