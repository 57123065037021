.ax-powerbar__icon-button {
    @include reset(button);

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    width: 40px;
    height: 40px;

    &.active,
    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
}