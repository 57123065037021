.ax-breadcrumbs {
        padding: 10px;
        font-family: $barlow-font-stack;

    &__items {
        display: inline-block;
        
        &:not(:last-of-type)::after {
            content: '/';
            margin: 0 5px;
            color: #B9BDC5; 
        }
    }   

    &__link {
        text-decoration: none;
        color: #515B6D;
        padding: 2px 4px 3px 4px; 
        
        &:hover {
            color:  #007A91;
        }
        &:focus {
            border: 1px solid #007A91;
            border-radius: 4px;
            outline: none;
            box-sizing: border-box;
        }
        &--active {
            font-weight: bold;
            &:hover {
                color:  #515B6D;
            }
        }
    }
    
    &-select {
        opacity: .01;
        inline-size: 1px;
    }

    &--crumbicon {
        --size: 3ch;
        display: grid;
        grid: [stack] var(--size)/[stack] var(--size);
        align-items: center;
        justify-items: center;
        place-items: center;
        border-radius: 50%;
        --icon-shadow-size: 0px;
        box-shadow: inset 0 0 0 var(--icon-shadow-size) currentColor;
        
        & > * {
            grid-area: stack;
        }
        & > svg {
            fill: none;
        }
    }

    &--crumbcontainer {
        display: inherit;
    }
}
