.ax-checkbox {
    position: relative;

    // Hide the real checkbox
    &:checked,
    &:not(:checked) {
        position: absolute;
        left: -9999px;
        opacity: 0;
    }

    & + label {
        font-family: $barlow-font-stack;
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        font-size: toRem(14);
        font-weight: 500;
        height: 20px;
        display: inline-block;
        color: color(int-warm-grey);
        display: flex;
        align-items: center;
        line-height: 1;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            z-index: 2;
            box-sizing: border-box;
        }

        &::after {
            content: '';
            @include backgroundIcon('checkmark', white, contain);
            width: 14px;
            height: 14px;
            position: absolute;
            top: 3px;
            left: 3px;
            -webkit-transition: all 0.05s linear;
            transition: all 0.05s linear;
            z-index: 3;
        }

        // Required
        &[required],
        &.required,
        &:required {
            &::after {
                content: '';
            }
        }
    }

    &:not(:checked) {
        & + label::before {
            border: 1px solid color(input-border);
            background: #fff;
        }

        & + label::after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }
    }

    &:checked {
        & + label::before {
            border: 1px solid transparent;
            background: color(class-teal);
        }

        & + label::after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    // Hover and focus states
    // https://www.tpgi.com/focus-visible-and-backwards-compatibility/
    &:focus + label::before,
    &:hover + label::before {
        border-color: darkenOnHover(class-teal);
        box-shadow: 2px 2px 4px rgba(0,0,0,0.1);
    }
    &:checked:focus + label::before,
    &:checked:focus:hover + label::before,
    &:checked:hover + label::before {
        background-color: darkenOnHover(class-teal);
    }
    &:focus:not(:focus-visible) + label::before {
        border-color: color(class-teal);
        box-shadow: none;
    }
    &:checked:focus:not(:focus-visible) + label::before {
        background-color: color(class-teal);
    }
    &:focus-visible + label::before {
        border-color: darkenOnHover(class-teal);
        box-shadow: 2px 2px 4px rgba(0,0,0,0.1);
    }
    &:checked:focus-visible + label::before,
    &:checked:focus:hover + label::before {
        background-color: darkenOnHover(class-teal);
    }


    &[disabled],
    &:disabled,
    &.disabled {
        & + label {
            pointer-events: none;
            cursor: not-allowed;
            color: color(disabled-text);
        }

        & + label::before {
            background-color: color(int-warm-grey-tint-5);
        }

        &:checked + label::before {
            background-color: color(int-teal-tint-10);
        }

        &:checked + label::after {
            @include backgroundIcon('checkmark', color(disabled-text), contain);
        }
    }

    &.indeterminate {
        & + label::after {
            @include backgroundIcon('minus', white, contain);
        }

        &[disabled],
        &:disabled,
        &.disabled {
            &:checked + label::after {
                @include backgroundIcon('minus', color(disabled-text), contain);
            }

        }

    }
}