
.powerbar-nav-top-level-item {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;

    button {
        @include reset(button);
        padding: 0 $link-padding;
        white-space: nowrap;
        font-family: $barlow-font-stack;
        font-weight: 600;
        font-size: 15px;
        color: $link-text-color;
        height: 32px;

        caption {
            color: inherit;
        }
    }

    &.active,
    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
}

