$link-padding: 8px;

.ax-results-panel {
    position: absolute;
    width: 100%;
    background: var(--class-deep-sea);
    padding: 10px $link-padding;
    border-radius: 0px 0px 8px 8px;
    box-sizing: border-box;

    &__group {
        margin: 0;
        padding: $link-padding 0;

        &:not(:last-of-type) {
            border-bottom: 1px solid var(--int-teal-tint-40);
        }
    }

    &__item {
        list-style-type: none;
        border-radius: 4px;

        &:hover,
        &.selected {
            background: rgba(255, 255, 255, 0.2);
            cursor: pointer;
            filter: brightness(0) invert(1);
        }
    }

    &__item-link {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr 60px 24px;
        padding: 3px $link-padding;
        color: var(--int-white);
        font-family: var(--barlow-font-stack);
        font-size: toRem(15);
        font-weight: 500;
        line-height: toRem(20);
        text-decoration: none;
    }

    &__item-label {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 30px;
    }

    &__item-shortcut {
        color: var(--int-teal-tint-40);
    }

    &__item-target {
        font-size: 18px;
        color: var(--int-washed-denim);
        text-align: right;
        
        &-link {
            color: var(--int-washed-denim);
        }
    }
}