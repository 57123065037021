@mixin buttonColors(
    $bg,
    $color,
    $focusBg,
    $focusColor,
    $hoverBg,
    $hoverColor,
    $activeBg,
    $activeColor,
    $disabledBg,
    $disabledColor
) {
    background-color: $bg;
    color: $color;
    &:focus {
        background-color: $focusBg;
        color: focusColor;
    }
    &:hover {
        background-color: $hoverBg;
        color: $hoverColor;
    }
    &:active,
    &:active:focus {
        background-color: $activeBg;
        color: $activeColor;
    }

    &:disabled,
    &[disabled],
    &.disabled,
    &.ax-btn--disabled {
        &,
        &:hover,
        &:active,
        &:active:focus,
        &:focus,
        &:focus-visible {
            background-color: $disabledBg;
            color: $disabledColor;
            box-shadow: none;
            cursor: not-allowed;
        }
    }
}

@mixin buttonKeyboardFocusState() {
    box-shadow: none;
    outline: 0;
    &::before {
        $focus-border-gap: 2px;
        $focus-border-stroke: 2px;
        $stroke-plus-gap: #{$focus-border-gap + $focus-border-stroke};

        position: absolute;
        content: '';
        left: calc((#{$stroke-plus-gap}) * -1);
        top: calc((#{$stroke-plus-gap}) * -1);
        width: calc(100% + (#{$stroke-plus-gap}) * 2);
        height: calc(100% + (#{$stroke-plus-gap}) * 2);
        border: $focus-border-stroke solid color(class-teal);
        border-radius: calc(4px + #{$stroke-plus-gap});
        box-sizing: border-box;
    }
}
.ax-btn,
a.ax-btn {
    // Reset
    @include reset(button);

    // Default styles
    padding: 0 15px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: toRem(40);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.35);
    font-family: $barlow-font-stack;
    font-style: normal;
    font-weight: 500;
    font-size: toRem(16);
    position: relative;
    transition: all 0.3s ease 0s;
    white-space: nowrap;

    // TODO: remove following 2 lines when global default font-family can be set.
    font-family: $barlow-font-stack;
    * {
        font-family: $barlow-font-stack;
    }

    @include buttonColors(
        color(class-teal),
        color(int-white),
        color(class-teal),
        color(int-white),
        darken(color(class-teal), 4.51),
        color(int-white),
        darken(color(class-teal), 4.51),
        color(int-white),
        lighten(desaturate(adjust-hue(color(class-teal), -2), 59.46), 64.31),
        color(disabled-text)
    );

    // https://www.tpgi.com/focus-visible-and-backwards-compatibility/
    &:focus { @include buttonKeyboardFocusState(); }
    &:focus:not(:focus-visible) {
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.35);
        outline: initial;
        border: none;
        &::before {
            display: none;
        }
    }
    &:focus-visible { @include buttonKeyboardFocusState(); }

    &:hover {
        text-decoration: none;
    }

    &:active:focus {
        box-shadow: none;
        outline: none;
        &::before {
            display: none;
        }
    }

    // Types
    &--icon {
        @extend .ax-btn;
        box-shadow: none;
        width: 40px;
        padding: 0;

        &.ax-btn--slim,
        &.ax-btn--compact {
            width: 30px;
        }

        @include buttonColors(
            transparent,
            #515b6d,
            rgba(color(class-teal), 0.2),
            #515b6d,
            rgba(color(class-teal), 0.2),
            #515b6d,
            rgba(color(class-teal), 0.3),
            #515b6d,
            transparent,
            color(disabled-text)
        );

        &,
        &:focus,
        &:focus:not(:focus-visible),
        &:hover:focus  {
            box-shadow: none;
            outline: none;
        }
    }

    &--icon[class*='ax-icon--'] {
        &::before {
            border-radius: 4px;
            border-width: 1px;
            left: 0;
            top: 0;
            width: calc(100%);
            height: calc(100%);
        }

        &::after {
            width: 50%;
            height: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    // Size Variants
    &--slim,
    &--compact {
        height: toRem(30);
        min-height: toRem(30);
    }

    // Color variants
    &--primary {
        @extend .ax-btn;
        @include buttonColors(
            color(class-teal),
            color(int-white),
            color(class-teal),
            color(int-white),
            darken(color(class-teal), 4.51),
            color(int-white),
            darken(color(class-teal), 4.51),
            color(int-white),
            #F5F6F7,
            color(disabled-text)
        );
    }

    &--secondary {
        @extend .ax-btn;
        @include buttonColors(
            color(int-teal-tint-10),
            color(class-teal),
            color(int-teal-tint-10),
            color(class-teal),
            darken(desaturate(adjust-hue(color(int-teal-tint-10), 2), 1.09), 14.12),
            color(class-teal),
            darken(desaturate(adjust-hue(color(int-teal-tint-10), 2), 1.09), 14.12),
            color(class-teal),
            #f5f6f7,
            color(disabled-text)
        );

        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
    }

    &--secondary-on-color {
        @extend .ax-btn;
        @include buttonColors(
            color(int-white),
            color(class-teal),
            color(int-white),
            color(class-teal),
            darken(desaturate(adjust-hue(color(int-teal-tint-10), 2), 1.09), 14.12),
            color(class-teal),
            darken(desaturate(adjust-hue(color(int-teal-tint-10), 2), 1.09), 14.12),
            color(class-teal),
            color(int-white),
            color(disabled-text)
        );

        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
    }

    &--tertiary,
    &--link {
        @extend .ax-btn;
        @include buttonColors(
            transparent,
            color(class-teal),
            transparent,
            color(class-teal),
            color(int-teal-tint-10),
            color(class-teal),
            transparent,
            #024B58,
            transparent,
            color(disabled-text)
        );
        &,
        &:focus,
        &:focus:not(:focus-visible),
        &:hover:focus  {
            box-shadow: none;
            outline: none;
        }
    }

    &--cautionary {
        @extend .ax-btn;
        @include buttonColors(
            color(status-red),
            color(int-white),
            color(status-red),
            color(int-white),
            darken(saturate(color(status-red), 5.91), 10.39),
            color(int-white),
            darken(saturate(color(status-red), 5.91), 10.39),
            color(int-white),
            #f5f6f7,
            color(disabled-text)
        );

        &:focus::before {
            border-color: color(status-red);
        }
    }
}
