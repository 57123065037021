%shadow-box {
  border-radius: 5px;
  padding: 14px;
  box-shadow: 2px 2px 10px #888888;
}

// Linear Gradient usage
// .button {
//   @include linearGradient(#cccccc, #666666);
// }
@mixin linearGradient($top, $bottom) {
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, $top),
        color-stop(100%, $bottom)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
        top,
        $top 0%,
        $bottom 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, $top 0%, $bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $top 0%, $bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom, $top 0%, $bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

// Reset mixin
// Use on elements by entering @include reset(<type>) at the top of your component definitions;
// This map-get method enables fine-grain reset definition for specific components if needed

$resets: (
    all: (
        all: unset
    ),
    general: (
        margin: 0,
        padding: 0,
        border: 0,
        font-size: 100%,
        -webkit-text-size-adjust: 100%,
        font: inherit,
        color: inherit,
        line-height: 1,
        -webkit-font-smoothing: inherit,
        -moz-osx-font-smoothing: inherit,
        vertical-align: baseline,
        list-style: none,
        quotes: none,
        border-collapse: collapse,
        border-spacing: 0,
        box-sizing: border-box,
    ),
    body: (
        margin: 0,
        padding: 0,
        font-family: $barlow-font-stack,
        font-size: toRem(16),
        font-weight: 400,
        line-height: 1,
        color: inherit,
        text-align: left,
        background-color: $ui-background
    ),
    ul: (
        list-style: none,
        margin: 0,
        padding: 0,
        li: (
            list-style: none,
            margin: 0,
            padding: 0,
        ),
    ),
    ol: (
        list-style: none,
        margin: 0,
        padding: 0,
        li: (
            list-style: none,
            margin: 0,
            padding: 0,
        ),
    ),
    a: (
        text-decoration: none,
        color: $brand-primary,
        font-size: inherit,
        line-height: inherit,
        font-weight: inherit,
        font-family: inherit,
        text-align: inherit,
    ),
    button: (
        border: none,
        margin: 0,
        padding: 0,
        width: auto,
        max-width: none,
        overflow: visible,
        background-color: transparent,
        color: inherit,
        font: inherit,
        line-height: normal,
        cursor: pointer,
        text-decoration: none,
        text-align: inherit,
        -webkit-font-smoothing: inherit,
        -moz-osx-font-smoothing: inherit,
        -webkit-appearance: none,
    ),
    table: (
        margin: 0,
        padding: 0,
        border: none,
        border-collapse: inherit,
        border-spacing: 0,
        border-color: inherit,
        vertical-align: inherit,
        text-align: left,
        font-weight: inherit,
        -webkit-border-horizontal-spacing: 0,
        -webkit-border-vertical-spacing: 0,
    ),
    blockquote: (),
    form: (),
    input: (
        all: unset,
    ),
    text: (
        font-family: $barlow-font-stack,
        font-size: 16px,
        font-style: normal,
        font-weight: normal,
        letter-spacing: 0,
        text-align: left,
        text-decoration: none,
        text-indent: 0,
        text-shadow: none,
        text-transform: none
    )
);

// Use this to reset a specific element.
// Some elements have nested styles, reset(ul) for example also includes styles for the <li>
@mixin reset($type) {
    @each $prop, $value in map-get($resets, $type) {
        @if type-of($value) == "map" {
            // TODO update to also handle pseudo elements
            // ':hover': (
            //     background: red,
            // )
            & #{$prop} {
                @each $sub-prop, $sub-value in $value {
                    #{$sub-prop}: $sub-value;
                }
            }
        } @else {
            #{$prop}: $value;
        }
    }
}

// Use this for the combination of all the resets in the map.  Apply to <body>
@mixin reset-global {
    @each $element, $map in $resets {
        // Apply body styles to class root
        @if $element == 'body' {
            @include reset(#{$element})
        }
        // exclude 'general'
        @if $element != 'general' and $element != 'body' {
            #{$element} {
                @include reset(#{$element})
            }
        }
    }
}