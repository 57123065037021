// DEFAULT SELECT -----------------------------------------
.ax-select {
    @extend .ax-input;
    padding-right: toRem(24);

    // Hide default arrow
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    &::-ms-expand { display: none; }

    // The chevron
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.9104 1.09387C14.1028 1.28935 14.1028 1.60628 13.9104 1.80176L7.93136 7.87886C7.73904 8.07434 7.42722 8.07434 7.23489 7.87886L1.25581 1.80176C1.06349 1.60628 1.06349 1.28935 1.25581 1.09387C1.44814 0.898396 1.75996 0.898396 1.95228 1.09387L7.58313 6.81703L13.214 1.09387C13.4063 0.898396 13.7181 0.898396 13.9104 1.09387Z' fill='%23007A91' stroke='%23007A91' stroke-width='0.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.9104 1.09387C14.1028 1.28935 14.1028 1.60628 13.9104 1.80176L7.93136 7.87886C7.73904 8.07434 7.42722 8.07434 7.23489 7.87886L1.25581 1.80176C1.06349 1.60628 1.06349 1.28935 1.25581 1.09387C1.44814 0.898396 1.75996 0.898396 1.95228 1.09387L7.58313 6.81703L13.214 1.09387C13.4063 0.898396 13.7181 0.898396 13.9104 1.09387Z' fill='%23A5ADBA' stroke='%23A5ADBA' stroke-width='0.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 7px bottom 16px;
    background-size: 13px 7px;

    &[multiple] {
        background-image: none; // Hides the chevron for default <select multiple>
    }

    & option {
        font-weight:normal;
        height: 40px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: toRem(7);
        box-sizing: border-box;

        &:hover {
            background-color: var(--int-teal-tint-10);
        }

        &:checked {
            background-color: var(--int-teal-tint-20);
        }
    }

    &--full-width {
        width: 100%;
        max-width: 100%;
    }

    // Disabled
    &[disabled],
    &:disabled,
    &.disabled {
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.9104 1.09387C14.1028 1.28935 14.1028 1.60628 13.9104 1.80176L7.93136 7.87886C7.73904 8.07434 7.42722 8.07434 7.23489 7.87886L1.25581 1.80176C1.06349 1.60628 1.06349 1.28935 1.25581 1.09387C1.44814 0.898396 1.75996 0.898396 1.95228 1.09387L7.58313 6.81703L13.214 1.09387C13.4063 0.898396 13.7181 0.898396 13.9104 1.09387Z' fill='%23A5ADBA' stroke='%23A5ADBA' stroke-width='0.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
}


// CUSTOM SELECT ------------------------------------------
details.ax-select {
    position: relative;
    display: inline-flex;
    box-sizing: border-box;
    padding: 2px;
    min-height: 40px;

    // background-image: none;
    &:focus,
    &:focus-visible,
    &:focus-within {
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.9104 1.09387C14.1028 1.28935 14.1028 1.60628 13.9104 1.80176L7.93136 7.87886C7.73904 8.07434 7.42722 8.07434 7.23489 7.87886L1.25581 1.80176C1.06349 1.60628 1.06349 1.28935 1.25581 1.09387C1.44814 0.898396 1.75996 0.898396 1.95228 1.09387L7.58313 6.81703L13.214 1.09387C13.4063 0.898396 13.7181 0.898396 13.9104 1.09387Z' fill='%23007A91' stroke='%23007A91' stroke-width='0.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    &[open] {
        z-index: 5;
    }

    summary {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        height: 100%;
        min-height: 34px;
        border-radius: 4px;
        padding: toRem(4) toRem(7) toRem(0) toRem(7);
        cursor: pointer;
        box-sizing: border-box;
        list-style: none;
        line-height: 1;
        font-family: $barlow-font-stack;
        font-size: toRem(14);
        font-weight: normal;
        isolation: isolate;

        // Placeholder element
        &::before {
            content: attr(placeholder);
            position: absolute;
            padding: 0 toRem(24) 0 toRem(7);
            left: 0;
            top:0;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            z-index: -1;
            font-family: $barlow-font-stack;
            font-style: normal;
            font-size: 14px;
            color: var(--disabled-text);
        }

        &::-webkit-details-marker {
            display: none;
        }

        &:focus {
            outline: none;
        }
    }

    // Fullscreen backdrop for click to close
    &[open] summary::after {
        content: '';
        display: block;
        width: 100vw;
        height: 100vh;
        background: transparent;
        position: fixed;
        top: 0;
        left: 0;
    }


    /* FAKE SELECT */
    summary.radios {
        counter-reset: radios;
    }

    summary.checkboxes {
        counter-reset: checkboxes;
    }

    input[type=radio] {
        counter-increment: radios;
    }

    input[type=checkbox] {
        counter-increment: checkboxes;
    }

    // The selected label / pills
    input[type=radio] {
        pointer-events: none; // To allow opening the dropdown while clicking label
    }

    input[type=radio],
    input[type=checkbox] {
        appearance: none;
        display: none;
        margin: 0;
    }

    input[type=radio]:checked,
    input[type=checkbox]:checked {
        display: inline;
    }

    input[type=radio]::after,
    input[type=checkbox]::after {
        content: attr(title);
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        min-height: 26px;
        box-sizing: border-box;
        margin-bottom: 4px; // Matches top padding of summary
        margin-right: 6px;
        white-space: nowrap;
    }

    input[type=radio]::after {
        width: 100%;
    }

    input[type=checkbox]::after {
        // Pill style labels
        background-color: var(--int-teal-tint-10);
        padding: 4px 32px 4px 8px;
        border-radius: 2em;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.861328 11.25L11.3613 0.75' stroke='%23515B6D' stroke-width='1.05' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.3613 11.25L0.861328 0.75' stroke='%23515B6D' stroke-width='1.05' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-position: right 8px top 50%;
        background-repeat: no-repeat;
        white-space: break-spaces;
    }

    &[disabled],
    &:disabled,
    &.disabled {
        input[type=radio]::after,
        input[type=checkbox]::after {
            color: var(--disabled-text);
        }
    }

    // THE DROPDOWN
    ul.list {
        counter-reset: labels;
    }

    ul {
        width: 100%;
        background: var(--int-white);
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        padding: 7px;
        margin: 0;
        box-sizing: border-box;
        border-radius: 5px;
        max-height: 200px;
        overflow-y: auto;
        list-style-type: none;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
    }

    li {
        margin: 0;
        padding: 0;
    }

    li:first-child {
        padding-top: 0;
    }

    li:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    // Each general item in dropdown
    label {
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
        height: 40px;
        border-radius: 5px;
        padding: 7px;

        &:hover {
            background-color: var(--int-teal-tint-10);
        }
    }

    // Simulated checkboxes in dropdown
    &--multi ul label {
        position: relative;
        padding-left: 36px; // tickbox width + 2 x 8px
        display: flex;
        align-items: center;

        &::before {
            content: '';
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            border-radius: 3px;
            z-index: 2;
            box-sizing: border-box;
            border: 1px solid var(--input-border);
            background: var(--int-white);
        }

        &.selected::before {
            background-color: var(--class-teal);
            @include backgroundIcon('checkmark', white, 80%);
        }
    }
}
