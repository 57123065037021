.ax-navbar {
    $link-padding: 14px;
    $link-text-color: #4c4c4c;
    $hover-border-radius: 4px;
    $menu-background: #d9ebef;
    $border-color: #d9d9d9;

    display: flex;
    align-items: stretch;
    min-height: 60px;
    background: color(int-white);
    border-bottom: 1px solid $border-color;
    padding: 0 24px;
    position: relative;
    z-index: 1150;

    &__logo-container {
        padding: 8px 0;
        margin-right: 20px;
    }

    &__links-container {
        flex: 1 0 auto;
        display: flex;
    }

    &__actions-container {
        flex: 0 0 auto;
        display: flex;
        align-items: stretch;
    }

    &__actions-group {
        display: flex;
        align-items: center;
        padding-right: $link-padding;

        &:not(:last-of-type) {
            border-right: 1px solid $border-color;
            // border-right: 3px solid red;
        }
    }

    &__toggle-container {
        display: flex;
        align-items: center;
        padding-left: $link-padding;
    }

    &__nav {
        display: flex;
        align-items: stretch;
        position: relative;
    }

    &__link {
        position: relative;
        display: flex;
        align-items: center;

        button {
            @include reset(button);
            padding: 0 $link-padding;
            white-space: nowrap;
            font-family: $barlow-font-stack;
            font-weight: 600;
            font-size: 15px;
            height: 100%;
            color: $link-text-color;

            caption {
                color: inherit;
            }
        }

        &--active,
        &:hover {
            background: $menu-background;
        }
    }

    &__icon-link {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__action-icon {
        @include reset(button);
        margin-left: 16px;
        display: flex;
        align-items: center;
        position: relative;
        width: 20px;

        & svg,
        &-svg {
            fill: #888888;
            width: 100%;
            height: 100%;

            &:hover {
                fill: color(class-spearmint);
            }
        }

        &--active svg,
        &--active.__action-icon--svg {
            fill: color(class-spearmint);
        }
    }

    &__second-level-container {
        position: absolute;
        top: 100%;
        left: 0;
        background: $menu-background;
        padding: 12px #{$link-padding / 2};
        border-radius: 0px 0px 8px 8px;
        display: flex;
        border: 1px solid rgba(151, 151, 151, 0.5);
        border-top: 1px solid $border-color;

        &--has-link-connector::before {
            content:"";
            position: absolute;
            left: $link-padding;
            right: unset;
            top: -8px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent $menu-background transparent;
            z-index:9999;

        }

        &--orient-right {
            right: 0;
            left: unset;

            &.ax-navbar__second-level-container--has-link-connector::before {
                right: 2px;
                left: unset;
            }
        }

        &::after {
            content: '';
            box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.08);
            border-radius: 0px 0px 8px 8px;
            z-index: -1;
            width: 100%;
            height: calc(100% - 8px);
            position: absolute;
            top: 8px;
            left: 0;
        }
    }

    &__second-level-column {
        display: table;
        border-collapse: collapse;
        height: 0;
        margin-top: 0;

        &:not(:last-child) {
            margin-right: 65px;
        }
    }

    &__sub-level-item-row {
        display: table-row;
        cursor: pointer;
        position: relative;
        /* Edge hack for child absolute positioning */
        transform: scale(1);

        &:hover {
            & > .ax-navbar__sub-level-item-col {
                background: #007a91;
                & > a, & > span{
                    filter: brightness(0) invert(1);
                    text-decoration: none;
                }
            }
        }
    }

    &__sub-level-item-col {
        display: table-cell;
        min-width: $hover-border-radius;

        &:first-of-type {
            border-top-left-radius: $hover-border-radius;
            border-bottom-left-radius: $hover-border-radius;
        }
        &:last-of-type {
            border-top-right-radius: $hover-border-radius;
            border-bottom-right-radius: $hover-border-radius;
        }
        &:only-of-type .ax-navbar__sub-level-menu-link {
            padding-right: #{$link-padding / 2};
        }
    }

    &__sub-level-menu-link {
        white-space: nowrap;
        padding-left: #{$link-padding / 2};
        color: $link-text-color;
        font-family: $barlow-font-stack;
        font-style: normal;
        font-weight: normal;
        font-size: toRem(15);
        line-height: toRem(24);
        list-style-type: none;
        text-decoration: none;
        display: block;
    }

    &__second-level-shortcut {
        padding: 0 $link-padding;
        color: #979797;
        font-family: $barlow-font-stack;
        font-style: normal;
        font-weight: normal;
        font-size: toRem(15);
    }

    &__third-level-indicator {
        width: 14px;
        height: 9px;
        display: block;
        padding-left: 32px;
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.636963 1.41431L5.32715 5.9868L0.636963 10.5594' stroke='%234C4C4C' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 8px 14px;
        background-position-x: calc(100% - #{$link-padding / 2});
        background-position-y: center;
        transition: transform 0.15s linear;
        z-index: 2;
    }

    &__third-level-menu {
        position: absolute;
        top: -11px;
        left: calc(100% + #{$link-padding / 2});
        background: $menu-background;
        list-style-type: none;
        border-radius: 0 8px 8px 8px;
        padding: 11px #{$link-padding / 2} 15px;
        border: 1px solid rgba(151, 151, 151, 0.5);
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.15);
    }

    &__break-row {
        display: table-row;
    }

    &__group-break {
        padding: 0 8px;

        hr {
            margin: 8px 0;
            height: 0;
            border: 0;
            border-top: 1px solid #C4DCED;
        }
    }
}
