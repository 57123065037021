// Import this file to axiom.scss and axiom-storybook.scss

.ax-spinner {
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    &--appear {
        display: flex;
    }
}
