.ax-toggle {
    display: flex;
    flex-direction: column;
    position: relative;

    // Hide the default control
    &__checkbox {
        // From Bootstrap
        @include visually-hidden;
    }

    // Toggled 'OFF' state
    &__slider {
        height: 30px;
        width: 50px;
        min-width: 50px;
        background-color: #A5ADBA;
        bottom: 0;
        cursor: pointer;
        left: -3px;
        position: relative;
        right: 0;
        top: 0;
        transition: .4s;
        border-radius: 30px;
        border: 3px solid transparent;
        background-clip: padding-box;

        &::after {
            background-color: #fff;
            top: 50%;
            transform: translate(0, -50%);
            content: "";
            height: 22px;
            width: 22px;
            left: 4px;
            position: absolute;
            transition: .4s;
            border-radius: 50%;
        }
    }

    &:hover &__slider {
        background-color: #6B778C;
    }

    &:focus { outline: none;}

    &__checkbox:focus + &__slider,
    &__checkbox:focus-visible + &__slider {
        -moz-box-shadow: 0 0 0 1px #A5ADBA;
        -webkit-box-shadow: 0 0 0 1px #A5ADBA;
        box-shadow: 0 0 0 1px #A5ADBA;
     }
    &__checkbox:focus:not(:focus-visible) + &__slider,
    &__checkbox:active:focus + &__slider {
        box-shadow: none;
    }

    // Toggle 'OFF' and disabled
    &__checkbox[disabled] + &__slider {
        background-color: #E2E6EC;
        cursor: not-allowed;

        &::after {
            background-color: #A5ADBA;
        }
    }


    // Toggled 'ON' state
    &__checkbox:checked + &__slider {
        background-color: color(class-teal);

        &:after {
            transform: translate( 20px, -50%);
        }

        &:hover {
            background-color: color(int-teal-shade-75);
        }
    }

    &__checkbox:focus:checked + &__slider,
    &__checkbox:focus:checked + &__slider {
        -moz-box-shadow: 0 0 0 1px color(class-teal);
        -webkit-box-shadow: 0 0 0 1px color(class-teal);
        box-shadow: 0 0 0 1px color(class-teal);
     }
    &__checkbox:focus:not(:focus-visible):checked + &__slider,
    &__checkbox:active:focus:checked + &__slider {
        box-shadow: none;
    }

    // Toggle 'ON and disabled
    &__checkbox:checked[disabled] + &__slider {
        background-color: #E5F2F4;
    }

    // Label text
    & > &__label-text {
        position: relative;
        top: 0;
        font-family: $barlow-font-stack;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #515B6D;
        display: inline-block;
        margin-bottom: 4px;
    }

    // TODO optional label text
    // TODO disabled class list

    &--compact {
        .ax-toggle__slider {
            height: 20px;
            width: 41px;
            min-width: 41px;
            &::after {
                height: 13px;
                width: 13px;
            }
        }
    }

    &--label-left {
        flex-direction: row;
        align-items: center;
        .ax-toggle__label-text {
            margin-right: 8px;
            margin-bottom: 0;
        }
    }
    &--label-right {
        flex-direction: row;
        align-items: center;
        .ax-toggle__label-text {
            margin-left: 4px;
            order: 2;
            margin-bottom: 0;
        }
    }
}


