@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url("#{$barlow-fonts-dir}Barlow-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}
