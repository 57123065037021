// LAYOUT OVERRIDES =======================================

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
);

$spacer: 32px;
$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer * 0.75,
    4: $spacer,
    5: $spacer * 1.5,
    6: $spacer * 3,
);

$gutter: 24px;
$grid-columns: 12 !default;
$grid-gutter-width: $gutter !default;

// @debug $spacer;
$gutters: (
    0: 0,
    1: $gutter * .25,
    2: $gutter * .5,
    3: $gutter * 0.75,
    4: $gutter,
    5: $gutter * 1.5,
    6: $gutter * 3,
);

// UTILITIES & HELPERS OVERRIDES ==========================

// COLORS -------------------------------------------------
// This overrides the default $theme-colors map with our own flat
// map of colors defined in src/styles/scss/abstracts/_colour-vars.scss
// Utilities that use the map, such as background, borders, and text
// will be generated from this list.  This means the available
// classes will be different to those in the Bootstrap 5 documentation.
// E.g. instead of `.text-primary` you could use `.text-int-teal`
$theme-colors: $colors;

$white:    color(int-white);
$gray-100: color(int-warm-grey-tint-10);
$gray-200: color(int-warm-grey-tint-30);
$gray-300: color(int-warm-grey-tint-50);
$gray-400: color(int-warm-grey-tint-75);
$gray-500: color(int-warm-grey);
$gray-600: color(int-warm-grey-shade-75);
$gray-700: color(int-warm-grey-shade-50);
$gray-800: color(int-warm-grey-shade-30);
$gray-900: color(int-warm-grey-shade-10);
$black:    color(int-black);

// BORDERS
$border-color:                color(int-warm-grey);
$border-radius:               8px;
$border-radius-sm:            4px;
$border-radius-lg:            16px;
$border-radius-pill:          50rem;  // Don't use percentage as loses pill shape

// SHADOWS
$box-shadow-sm: 2px 2px 3px rgba(color(int-black), .25);
$box-shadow: 0px 8px 8px rgba(color(int-black), .15);
$box-shadow-lg: 0 16px 16px rgba(color(int-black), .1);
$box-shadow-inset: inset 0 1px 2px rgba(color(int-black), .075);


// FONT-FAMILY --------------------------------------------
$font-family-sans-serif:    $barlow-font-stack;
$font-family-monospace:     $monospace-font-stack;
$font-family-base:          var(--barlow-font-stack);
$font-family-code:          var(--monospace-font-stack);

// WEIGHTS ------------------------------------------------
// Default map vars
$font-weight-lighter:       200; // Barlow-ExtraLight.ttf
$font-weight-light:         300; // Barlow-Light.ttf
$font-weight-normal:        400; // Barlow-Regular.ttf
$font-weight-bold:          700; // Barlow-Bold.ttf
$font-weight-bolder:        800; // Barlow-ExtraBold.ttf
$font-weight-base:          $font-weight-normal;

// Additional vars
// (added to utilities map in src/components/01-foundation/utilities/utilities.scss
$font-weight-thin:          100; // Barlow-Thin.ttf
$font-weight-medium:        500; // Barlow-Medium.ttf
$font-weight-semibold:      600; // Barlow-SemiBold.ttf
$font-weight-black:         900; // Barlow-Black.ttf



// SIZES --------------------------------------------------
// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:            16px;
$font-size-base:            0.875rem; // 14px
$font-size-sm:              $font-size-base * .75;  // 12px
$font-size-lg:              $font-size-base * 1.125; // 18px

$h1-font-size:              $font-size-root * 1.5;      // 24px
$h2-font-size:              $font-size-root * 1.125;    // 18px
$h3-font-size:              $font-size-root;            // 16px
$h4-font-size:              $font-size-root * 0.875;    // 14px
$h5-font-size:              $font-size-root * 0.75;     // 12px
$h6-font-size:              $font-size-root * 0.75;     // 12px (not used in designs)

// Custom font-size suffixes added to utilities map
// in src/components/01-foundation/utilities/utilities.scss



// LINE HEIGHT --------------------------------------------
// Custom line-height suffixes added to utilities map
// in src/components/01-foundation/utilities/utilities.scss

