.ax-banner {
    width: calc(100% - 2.5px);
    font-family: $barlow-font-stack;
    color: var(--int-dark-grey);
    font-weight: 600;
    font-size: 1em;
    line-height: 1.25em;
    border-radius: 4px;
    display: flex;
    position: relative;
    right: 0;
    left: 0;
    top: 0;
    background: var(--int-white);

    &__low-attention {
        border: 1.25px solid var(--status-green);
    }

    &__medium-attention {
        border: 1.25px solid var(--status-orange);
    }

    &__high-attention {
        border: 1.25px solid var(--status-red);
    }
    .icon-wrapper {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        margin-left: -0.25px;
        margin-top: -0.25px;
        margin-bottom: -0.25px;
        padding: 0 14px;

        &__low-attention {
            background-color: var(--status-green);
        }
    
        &__medium-attention {
            background-color: var(--status-orange);
        }
    
        &__high-attention {
            background-color: var(--status-red);
        }
    }

    .icon {
        min-width: 1.25em;
        min-height: 1.25em;
        margin-right: 0;  
        color: var(--int-white);

        &__low-attention {
            background-color: var(--status-green);
            height: 100%;
        }
 
        &__medium-attention {
            background-color: var(--status-orange);
            height: 100%;
        }
    
        &__high-attention {
            background-color: var(--status-red);
            height: 100%;
        }
    }

    &--message {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-right: 24px;

        &-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 24px;
        }

        &__title {
            display: block;
            font-weight: 600;
            font-size: 18px;
            line-height: 21.6px;
            color: var(--int-dark-grey);
            padding-bottom: 18px;
        }

        &__body {
            font-weight: 500;
            font-size: 14px;
            line-height: 16.8px;
            color: var(--status-grey);
        }
    }

    &--button {
        display: flex;
        align-items: center;

        button {
            width:auto;
            height:auto;
            overflow:auto;
        }
    }
}

@media only screen and (max-width: 28.125em) {

    .ax-banner {
        width: 85%;
        right: 0;
        left: 0;
    }
}

@media only screen and (min-width: 87.5em) {
    .ax-banner {
        right: 2.5em;
    }
}