.powerbar-help-panel {
    &__group-heading {
        font-family: $barlow-font-stack;
        font-weight: 700;
        color: var(--int-white);
        padding: 3px 6px;
        margin: 32px 0 6px;
        font-size: 12px;
    }

    &__links-group {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    &__links-group-item {
        padding: 3px 6px;
        margin-bottom: 6px;

        &:hover {
            background: rgba(255, 255, 255, 0.2);
            border-radius: 4px;
        }
    }

    &__link {
        font-family: $barlow-font-stack;
        font-weight: 500;
        color: var(--int-white);
        font-size: toRem(15);
        display: block;
    }
}
