$root-dir: '@/';

$brand-primary: color(class-indigo) !default;
$base: color(int-warm-grey-tint-40) !default;
$container: color(int-warm-grey-tint-10) !default;
$ui-background: color(int-white) !default;

//system colours
$system-green: color(status-green) !default;
$system-red: color(status-red) !default;
$system-purple: color(class-amethyst) !default;
$system-orange:color(status-orange) !default;

// abstraction
$system-notification: color(class-amethyst) !default;
$system-error: color(status-red) !default;
$system-warn: color(status-orange) !default;
$system-success: color(status-green) !default;

/* Roboto stack */
$roboto-font-stack: roboto, Helvetica, Arial, sans-serif !default;
$roboto-fonts-dir: '@/assets/font-files/roboto/';

/* Barlow stack */
$barlow-font-stack: 'Barlow', "Trebuchet MS", Helvetica, Arial, sans-serif !default;
$barlow-fonts-dir: '@/assets/font-files/Barlow/';

/* Monospace stack */
$monospace-font-stack: 'Roboto Mono', Consolas, "Lucida Console", Monaco, Courier, monospace !default;
$monospace-fonts-dir: '@/assets/font-files/roboto-mono/';

// Add to :root
:root {
  --barlow-font-stack: #{$barlow-font-stack};
  --roboto-font-stack: #{$roboto-font-stack};
  --monospace-font-stack: #{$monospace-font-stack};
}

$_text-inputs-list: (
  "[type='color']",
  "[type='date']",
  "[type='datetime']",
  "[type='datetime-local']",
  "[type='email']",
  "[type='month']",
  "[type='number']",
  "[type='password']",
  "[type='search']",
  "[type='tel']",
  "[type='text']",
  "[type='time']",
  "[type='url']",
  "[type='week']",
  "input:not([type])",
  "textarea",
);