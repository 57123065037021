@mixin toggleApplyColors($bg, $fg, $hoverbg, $hoverfg, $disabledbg, $disabledfg) {
    @include backgroundIcon('chevron-down', $fg);
    border-color: $fg;
    background-color: $bg;
    color: $fg;

    &:hover,
    &:checked {
        background-color: $hoverbg;
        color: $hoverfg;
    }

    &:disabled,
    &[disabled],
    &.disabled {
        border-color: $disabledfg;
        background-color: $disabledbg;
        color: $disabledfg;
        @include backgroundIcon('chevron-down', $disabledfg);
        cursor: not-allowed;
    }
}

.ax-multi-action-btn {
    position: relative;
    display: inline-block;
    align-self: self-start;

    &  .ax-btn {
        padding-right: 55px;

        &--secondary ~ .ax-multi-action-btn__toggle {
            @include toggleApplyColors(
                color(int-teal-tint-10),
                color(class-teal),
                darken(desaturate(adjust-hue(color(int-teal-tint-10), 2), 1.09), 14.12),
                color(class-teal),
                #F5F6F7,
                color(disabled-text)
            );
        }

        &--secondary-on-color ~ .ax-multi-action-btn__toggle  {
            @include toggleApplyColors(
                color(int-white),
                color(class-teal),
                darken(desaturate(adjust-hue(color(int-teal-tint-10), 2), 1.09), 14.12),
                color(class-teal),
                color(int-white),
                color(disabled-text)
            );
        }

        &--tertiary,
        &--link {
            padding-right: toRem(35);

            &  ~ .ax-multi-action-btn__toggle  {
                @include toggleApplyColors(
                    transparent,
                    color(class-teal),
                    color(int-teal-tint-10),
                    color(class-teal),
                    transparent,
                    color(disabled-text)
                );
                width: toRem(30);
                border-color: transparent;
            }
        }

        &--cautionary ~ .ax-multi-action-btn__toggle  {
            @include toggleApplyColors(
                color(status-red),
                color(int-white),
                darken(saturate(color(status-red), 5.91), 10.39),
                color(status-red),
                color(int-white),
                color(disabled-text)
            );
        }

        &--slim ~ .ax-multi-action-btn__toggle {
            background-size: 14px;
        }
    }

    &__toggle {
        @include reset(button);

        position: absolute;
        padding-right: 0;
        right: -1px;
        left: unset;
        top: 0;
        width: toRem(40);
        max-width: toRem(40);
        box-shadow: none;
        height: 100%;
        border-left: 1px solid color(int-white);
        border-radius: 0 4px 4px 0;

        @include toggleApplyColors(
            color(class-teal),
            color(int-white),
            darken(color(class-teal), 4.51),
            color(int-white),
            lighten(desaturate(adjust-hue(color(class-teal), -2), 59.46), 64.31),
            color(disabled-text)
        );

        &:checked {
            & ~ .ax-multi-action-btn__menu {
                display: block;
                visibility: visible;
                opacity: 1;
            }
        }

        &::before {
            display: none; // Class HUI hack
        }
    }

    &__menu {
        @include reset(ul);

        position: absolute;
        top: calc(100% + 8px);
        width: 100%;
        background: color(int-white);
        border: 0.5px solid #E1E1E1;
        box-sizing: border-box;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: toRem(6) 0;
        font-family: $barlow-font-stack;
        display: none;
        visibility: hidden;
        opacity: 0;
        max-height: toRem(225);
        overflow-y: auto;
        z-index: 9;
    }

    &__item {
        @include reset(a);
        @include reset(button);

        display: block;
        width: 100%;
        padding: 8px 15px;
        box-sizing: border-box;
        font-size: toRem(14);

        &:hover,
        &:focus {
            background: #E5E5E5;
        }

        &:disabled,
        &[disabled],
        &.disabled {
            color: color(disabled-text);
            background: transparent;
            cursor: not-allowed;
        }
    }

    // VARIANTS
    // - Menu position drop up
    &--drop-up {
        .ax-multi-action-btn__menu {
            top: unset;
            bottom: calc(100% + 8px);
        }
        .ax-multi-action-btn__toggle {
            transform: rotate(180deg);
            border-radius: 4px 0 0 4px;
            border-right: 1px solid currentColor;
            border-left: none;
        }
    }

    // TODO
    // - Single button?
}