.ax-table {
    box-sizing: border-box;
    width: 100%;
    line-height: 16px;
    border-spacing: 0;
    border: 1px solid var(--int-warm-grey-tint-50);
    border-radius: 4px;
    font-family: 'Barlow';
    text-align: left;
    white-space: nowrap;
    border-collapse: separate;
    font-size: 14px;
    color: var(--int-dark-grey);
    overflow: scroll;
    height: 100%;
    position: relative;

    td, th {
        overflow: hidden;
        white-space: nowrap;
        -moz-text-overflow: ellipsis;        
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        position: relative;
        z-index: 0;
    }
    
    .align-right {
        text-align: right;
        & > .ax-table--header-sort {
            justify-content: end;
        }
    }

    .align-center {
        text-align: center;
        min-width: 65px;
    }

    .align-left {
        text-align: left;
    }

    .currency {
        font-family: 'Roboto Mono';
        font-weight: 400;
    }

    &--none {
        font-size: 0;
    }

    col:nth-child(3) {
        border-right: 2px solid red; 
    }

    &--col-resize {
        top: 0;
        right: 0;
        width: 4px;
        position: absolute;
        cursor: url("data:image/svg+xml,%3Csvg width='10' height='21' viewBox='0 0 10 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.127289 21L0.12729 0.222168L2.12729 0.222168L2.12729 21H0.127289Z' fill='%23FF9533'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.127289 21L0.12729 0.222168L2.12729 0.222168L2.12729 21H0.127289Z' fill='%23FF9533'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.93509 21L7.93509 0.222168L9.93509 0.222168L9.93509 21H7.93509Z' fill='%23FF9533'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.93509 21L7.93509 0.222168L9.93509 0.222168L9.93509 21H7.93509Z' fill='%23FF9533'/%3E%3C/svg%3E%0A"), auto;
        user-select: none;
        height: 100%;
        
        &-example {
            border-right: 2px solid #FF9533;
        }
    }

    &--header {
        &-sticky {
            position: sticky !important;
            top: 0;
            z-index: 14;
        }

        &-sort {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: end;
            height: 100%;

            &-arrows {
                position: relative;
                right: -5px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-left: 5px;
    
                &-size {
                    width: 10px;
                    height: 11px;
                    cursor: pointer;
                }

                &-deactivated {
                    opacity: 0.3;
                }
            }
        }

        tr {
            overflow: auto;
            
            th {
                white-space: initial;
                background-color: var(--int-warm-grey);
                color: var(--int-white);
                border-right: 1px solid white;
                font-weight: 500;
                height: 32px;
                vertical-align: bottom;
                padding: 8px 12px;
                z-index: 1;

                &:first-child {
                    border-top-left-radius: 3px;
                }

                &:last-child {
                    border: none;
                    border-top-right-radius: 3px;
                }
                &.ax-table--header-check {
                    vertical-align: middle;
                    padding: 8px 11.5px;
                    label {
                        width: 20px;
                        margin: 0 auto;
                        padding: 0;
                    }
                }
                &.ax-table--sticky-col {
                    clip-path: inset(0 -8px 0px 0px);
                }
                
            }
        }
        &-dots {
            font-size: 0;
        }
        &-compact {
            tr {
                height: auto;
                overflow: auto;
                th {
                    height: auto;
                    padding: 6px 12px;
                    vertical-align: middle;
                    max-width: 76px; // This could be changed based on your text length
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &.ax-table--header-check {
                        padding: 6px 15px;
                    }
                    &:last-child {
                        padding: 0;
                    }
                    & > .ax-table--header-sort {
                        align-items: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    &.ax-table--header-compact-sorting {
                        max-width: none;
                    }
                }
            }
        }
        &-relaxed {
            tr {
                height: auto;
                overflow: auto;
                th {
                    padding: 12px;
                    vertical-align: bottom;

                    &.ax-table--header-check {
                        padding: 12px 15px;
                    }
                    &:last-child {
                        padding: 0;
                    }
                }
            }
        }
    }

    &--shadow {
        top: 48px;
        z-index: 13;
        position: sticky !important;
        -webkit-box-shadow: 0 0 4px 4px rgb(0 0 0 / 25%);
        -moz-box-shadow: 0 0 4px 4px rgb(0 0 0 / 25%);
        box-shadow: 0 0 4px 4px rgb(0 0 0 / 25%);
        clip-path: inset(0px 0px -8px 0px);

    }

    &--shadow-compact {
        top: 40px;
    }

    &--shadow-relaxed {
        top: 56px;
    }

    &--subheader {
        th {
            background-color: var(--int-teal-tint-10);
            padding: 12px;
            font-weight: 700;
    
            &:hover {
                background-color: transparent;
            }
        }
    }

    &--body {
        position: relative;

        &-check {
            label {
                width: 20px;
                margin: 0 auto;
                padding: 0;
            }
        }
    
        tr {
            position: relative;
           
            &:nth-child(odd) {
                background: #FFFFFF;
            }
            &:nth-child(even) {
                background: #F5F6F9;
            }
            &:hover {
                background: var(--int-teal-tint-10);
            }
            td {
                font-weight: 500;
                padding: 8px 12px;
                max-width: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                background: inherit;
                z-index: 0;

                &.ax-table--body-dots {
                    color: var(--int-warm-grey);
                    font-size: 18px;
                    padding: 4px 12px;
                    position: initial;
                    min-width: 40px;
                    max-width: 40px;

                    .ax-table--body-dots-button {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;  
                        min-width: 40px;
                        max-width: 40px;  

                        &:hover {
                            background-color: var(--int-teal-tint-30);
                        }

                        label {
                            cursor: pointer;
                            width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 4px;

                            i {
                                font-size: 18px;
                            }
                        }
                        
                        [type="radio"].open {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            margin: 0;
                            z-index: 1;
                            display: none;

                            &:checked + label {
                                display: none;
                            }

                            &:checked ~ .content {
                                z-index: 1;
                                display: block;
                                opacity: 1;
                            }

                            &:checked ~ [type="radio"] {
                                display: none;
                                opacity: 0;
                            }

                            &:checked ~ [type="radio"] + label {
                                z-index: 1;
                                display: flex;
                                opacity: 1;
                                background-color: var(--int-teal-tint-30);
                            }
                          
                            &:not(checked) ~ [type="radio"] + label {
                                z-index: -999;
                                display: none;
                                opacity: 0;
                            }

                            &:not(checked) ~ .content {
                                opacity: 0;
                                display: none;
                            }
                            
                        }

                        [type="radio"].close {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            margin: 0;
                            z-index: 1;
                            display: none;

                            &:checked + label {
                                display: none;
                            }

                            &:checked ~ .content {
                                display: none;
                                opacity: 0;
                            }

                            &:not(checked) ~ .content {
                                opacity: 0;
                                display: none;
                            }
                            
                        }

                        .content {
                            position: absolute;
                            top: calc(100% - 12px);
                            display: none;
                            width: auto;
                            min-width: 200px;
                            opacity: 0;
                            right: 12px;
                            background: var(--int-teal-tint-30);
                            border-radius: 4px 0 4px 4px;
                            padding: 18px 10px;
                            z-index: 9999999999999;
                            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);

                            ul {
                                margin: 0;
                                padding: 0;
                                list-style: none;
                                font-size: 15px;
                                line-height: 25px;
                                a {
                                    display: block;
                                    white-space: nowrap;
                                    text-decoration: none;
                                    border-radius: 4px;
                                    margin-bottom: 5px;
                                    color: var(--int-dark-grey);
                                    padding: 0 5px;

                                    a:last-child {
                                        margin-bottom: 0;
                                    }

                                    &:hover {
                                        background-color: var(--int-teal-tint-10);
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
            &:last-child {
                td {
                    &:first-child {
                        border-bottom-left-radius: 3px;
                    }

                    &:last-child {
                        border-bottom-right-radius: 3px;
                    }
                }
            }
        }
        &-compact {
            tr {
                height: 40px;

                td {
                    padding: 6px 12px;
                    &.ax-table--body-dots {
                        padding: 4px 12px;
                        max-width: 32px;
                        min-width: 32px;
                        .ax-table--body-dots-button {
                            max-width: 32px;
                            min-width: 32px;
                            height: 32px;
                            label {
                                width: 32px;
                                height: 32px;
                            }
                        }
                    }
                }
            }
        }
        &-relaxed {
            tr {
                height: 56px;

                td {
                    padding: 12px;
                    &.ax-table--body-dots {
                        padding: 4px 12px;
                        max-width: 48px;
                        min-width: 48px;
                        .ax-table--body-dots-button {
                            max-width: 48px;
                            min-width: 48px;
                            height: 48px;
                            label {
                                width: 48px;
                                height: 48px;
                            }
                        }
                    }
                }
            }
        }
    }

    &--footer {
        background-color: var(--int-warm-grey-tint-20);
        position: relative;
        tr {
            height: 48px;
            td {
                background-color: var(--int-warm-grey-tint-20);
                font-weight: 700;
                padding: 8px 12px;

                &.currency {
                    font-weight: 700;
                }
            }
        }

        &-compact {
            tr {
                height: 40px;

                td {
                    padding: 6px 12px;
                }
            }
        }
        &-relaxed {
            tr {
                height: 56px;

                td {
                    padding: 12px;
                }
            }
        }
    }

    &--skeleton {
        width: 90vw;
        thead {
            tr {
                th {
                    background-color: var(--int-warm-grey-tint-40);
                    position: relative;
                    animation: skeletonshimmer 1s ease-in-out infinite;
                    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 20% 40%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 60% 80%, rgba(255, 255, 255, 0) 80%);
                    background-repeat: no-repeat;
                }
            }
        }
        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: #FFFFFF;
                    position: relative;
                    animation: skeletonshimmer 1s ease-in-out infinite;
                    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 20% 40%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0) 60% 80%, rgba(255, 255, 255, 0) 80%);
                    background-repeat: no-repeat;
                }
                &:nth-child(even) {
                    background-color: #F5F6F9;
                    position: relative;
                    animation: skeletonshimmer 1s ease-in-out infinite;
                    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 20% 40%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0) 60% 80%, rgba(255, 255, 255, 0) 80%);
                    background-repeat: no-repeat;
                }
            }
        }
    }

    &--sticky-col {
        position: -webkit-sticky;
        position: sticky !important;
        left: 0;
        z-index: 12 !important;

        &-second {
            left: 44px;
            min-width: 212px;
        }

        &-shadow {
            background-color: #FFC300;
            box-shadow: 0 0 10px 5px rgba(0,0,0,0.20);
            clip-path: inset(0 -15px 0 0);
        }
    }

    @keyframes skeletonshimmer {
        0%{
            background-position: -800px 0;
        }
        100%{
            background-position: 800px 0;
        }
    }
}
