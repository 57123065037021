.ax-toast {
    width: 22.25em;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
    font-family: $barlow-font-stack;
    font-weight: 600;
    font-size: 1em;
    line-height: 1.25em;
    padding: 1em 1.375em;
    border-radius: 5px;
    display: flex;
    position: fixed;
    right: 3.125em;
    top: 1em;
    opacity: 0;
    transition: opacity 0.5s ease-out;

    &__open {
        opacity: 1;
        transition: opacity 0.5s ease-in;
        transition-delay: 0.5s;
    }

    &__hide {
        display: none;
    }

    &__info {
        background-color: var(--int-dark-grey);
    }

    &__success {
        background-color: var(--status-green);
    }

    &__warning {
        background-color: var(--status-orange);
    }

    &__error {
        background-color: var(--status-red);
    }

    .icon {
        min-width: 1.25em;
        min-height: 1.25em;
        margin-right: 1em;
        position: absolute;
        color: var(--int-white);

        &__warning {
            color: var(--int-dark-grey);
        }
    }

    .message {
        margin-right: calc(1em + 11px); // 16px(the margin) + 11px(the with of cross)
        margin-left: 2.25em; // 16px(the margin) + 20px(the width of icon)
        color: var(--int-white);
        display: inline;

        &__warning {
            color: var(--int-dark-grey);
        }

        * {
            margin: 0;
            padding: 0;
            display: inline;
        }
    }

    .cross {
        cursor: pointer;
        font-size: 11px;
        min-width: 11px;
        min-height: 11px;
        margin-left: 1em;
        position: absolute;
        right: calc(1.25em + 5.5px);
        top: calc(1em + 5.5px);
        color: var(--int-white);

        &__success {
            display: none;
        }
        &__warning {
            color: var(--int-dark-grey);
        }
        &__close {
            display: none;
        }
    }

}

.fade-out {
    animation: fade 500ms;
    -webkit-animation: fade 500ms;
    -moz-animation: fade 500ms;
  }

  /* Animate opacity */
  @keyframes fade {
    from { opacity: 1 }
    to { opacity: 0 }
  }
  @-moz-keyframes fade {
    from { opacity: 1 }
    to { opacity: 0 }
  }
  @-webkit-keyframes fade {
    from { opacity: 1 }
    to { opacity: 0 }
  }

@media only screen and (max-width: 28.125em) {

    .ax-toast {
        width: 85%;
        right: 0;
        left: 0;
    }
}

@media only screen and (min-width: 87.5em) {
    .ax-toast {
        right: 2.5em;
    }
}
