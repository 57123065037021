.ax-pagination {
    display: flex;
    align-items: center;

    &__pages {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    &__page-item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        width: toRem(40);
        height: toRem(40);

        &:first-of-type {
            margin-left: toRem(8)
        }
        &:last-of-type {
            margin-right: toRem(8)
        }

        &.active {
            background-color: color(int-teal-opacity-20);
            pointer-events: none;
            a{ color: color(int-dark-grey); }
        }

        &.collapse {
            &:empty {
                display: inline-flex;
            }

            &::before {
                content: unicode("2026");
                font-family: $barlow-font-stack;
                text-decoration: none;
                color: color(int-teal);
                font-weight: 600;
                font-size: toRem(16);
            }
        }
    }

    &__page-link {
        font-family: $barlow-font-stack;
        text-decoration: none;
        color: color(int-teal);
        font-weight: 600;
        font-size: toRem(16);
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &__previous,
    &__previous[class*="ax-btn"] {
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.1367 7.59436C16.1367 8.14665 15.689 8.59436 15.1367 8.59436L1.13672 8.59436C0.584434 8.59436 0.136719 8.14665 0.136719 7.59436C0.136719 7.04208 0.584433 6.59436 1.13672 6.59436L15.1367 6.59436C15.689 6.59436 16.1367 7.04208 16.1367 7.59436Z' fill='%23007A91'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.37703 14.8348C7.9865 15.2253 7.35334 15.2253 6.96282 14.8348L0.429481 8.30146C0.038957 7.91094 0.0389569 7.27777 0.429481 6.88725L6.96281 0.353912C7.35334 -0.0366124 7.9865 -0.0366125 8.37703 0.353911C8.76755 0.744436 8.76755 1.3776 8.37703 1.76813L2.5508 7.59435L8.37703 13.4206C8.76755 13.8111 8.76755 14.4443 8.37703 14.8348Z' fill='%23007A91'/%3E%3C/svg%3E");
        background-position: 14px 13px;
        padding-left: 39px;
        background-size: 16px;
        background-repeat: no-repeat;

        &:disabled,
        &[disabled],
        &.disabled {
            background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.1367 7.59436C16.1367 8.14665 15.689 8.59436 15.1367 8.59436L1.13672 8.59436C0.584434 8.59436 0.136719 8.14665 0.136719 7.59436C0.136719 7.04208 0.584433 6.59436 1.13672 6.59436L15.1367 6.59436C15.689 6.59436 16.1367 7.04208 16.1367 7.59436Z' fill='%23A5ADBA'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.37703 14.8348C7.9865 15.2253 7.35334 15.2253 6.96282 14.8348L0.429481 8.30146C0.038957 7.91094 0.0389569 7.27777 0.429481 6.88725L6.96281 0.353912C7.35334 -0.0366124 7.9865 -0.0366125 8.37703 0.353911C8.76755 0.744436 8.76755 1.3776 8.37703 1.76813L2.5508 7.59435L8.37703 13.4206C8.76755 13.8111 8.76755 14.4443 8.37703 14.8348Z' fill='%23A5ADBA'/%3E%3C/svg%3E");
        }
    }

    &__next,
    &__next[class*="ax-btn"] {
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.527344 7.59436C0.527344 7.04208 0.975059 6.59436 1.52734 6.59436L15.5273 6.59436C16.0796 6.59436 16.5273 7.04208 16.5273 7.59436C16.5273 8.14665 16.0796 8.59436 15.5273 8.59436L1.52734 8.59436C0.975059 8.59436 0.527343 8.14665 0.527344 7.59436Z' fill='%23007A91'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.28703 0.353928C8.67756 -0.0365958 9.31072 -0.0365957 9.70125 0.353929L16.2346 6.88726C16.6251 7.27779 16.6251 7.91095 16.2346 8.30148L9.70124 14.8348C9.31072 15.2253 8.67756 15.2253 8.28703 14.8348C7.89651 14.4443 7.89651 13.8111 8.28703 13.4206L14.1133 7.59437L8.28703 1.76814C7.89651 1.37762 7.89651 0.744453 8.28703 0.353928Z' fill='%23007A91'/%3E%3C/svg%3E%0A");
        background-position: right 14px top 13px;
        padding-right: 39px;
        background-size: 16px;
        background-repeat: no-repeat;

        &:disabled,
        &[disabled],
        &.disabled {
            background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.527344 7.59436C0.527344 7.04208 0.975059 6.59436 1.52734 6.59436L15.5273 6.59436C16.0796 6.59436 16.5273 7.04208 16.5273 7.59436C16.5273 8.14665 16.0796 8.59436 15.5273 8.59436L1.52734 8.59436C0.975059 8.59436 0.527343 8.14665 0.527344 7.59436Z' fill='%23A5ADBA'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.28703 0.353928C8.67756 -0.0365958 9.31072 -0.0365957 9.70125 0.353929L16.2346 6.88726C16.6251 7.27779 16.6251 7.91095 16.2346 8.30148L9.70124 14.8348C9.31072 15.2253 8.67756 15.2253 8.28703 14.8348C7.89651 14.4443 7.89651 13.8111 8.28703 13.4206L14.1133 7.59437L8.28703 1.76814C7.89651 1.37762 7.89651 0.744453 8.28703 0.353928Z' fill='%23A5ADBA'/%3E%3C/svg%3E%0A");
        }
    }
}