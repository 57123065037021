.ax-input {
    // Generic
    background-color: color(int-white);
    border: 1px solid color(input-border);
    border-radius: 4px;
    min-height: 40px;
    min-width: 180px;
    padding: toRem(11);
    line-height: 1;
    font-family: $barlow-font-stack;
    font-size: toRem(14);
    box-sizing: border-box;
    font-weight: normal;
    color: color(int-dark-grey);

    &:focus, &:focus-visible, &:focus-within {
        border-color: var(--class-teal);
        box-shadow: inset 0 0 3px -2px var(--class-teal);
        outline: none;
        color: color(int-dark-grey);
    }

    &::placeholder {
        color: color(input-border);
    }

    &--no-min-width {
        min-width: none;
    }

    &--full-width {
        width: 100%;
    }

    &.has-icon-leading {
        padding-left: 2.5em;
    }

    &.has-icon-trailing {
        padding-right: 2.5em;
        background-position: right toRem(12) center;
    }

    // Required
    &[required],
    &.required,
    &:required {
        // TBC
    }

    // Disabled
    &[disabled],
    &:disabled,
    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        background-color: color(int-warm-grey-tint-5);
        border-color: color(int-warm-grey-tint-20);
    }

    // Readonly / plain text
    &[readonly],
    &:readonly,
    &.readonly {

    }
}

// Optional wrapper, used for:
// - functional icons
// - Label positioning
.ax-input-wrapper {
    display: flex;
    align-items: center;

    // TODO contain label width
    // TODO Label positions
    // TODO replicate states when class on wrapper

    & > i {
        cursor: pointer;
        color: color(int-warm-grey);
        width: 0;
        font-size: 13px;

        &::after {
            width: 2.5em;
        }

        &:hover {
            color: color(int-teal);
        }
    }

    &.has-icon-leading {
        & > input {
            padding-left: 2.5em;
        }

        & > i {
            &::after {
                mask-position: toRem(13) 50%;
                -webkit-mask-position: toRem(13) 50%;
            }
        }
    }

    &.has-icon-trailing {
        & > input {
            padding-right: 2.5em;
        }

        & > i {
            transform: translateX( -2.5em );

            &::after {
                mask-position: right toRem(13) top 50%;
                -webkit-mask-position: right toRem(13) top 50%;
            }
        }
    }

    & .ax-validation-feedback {
        margin-top: 0;
        margin-left: toRem(12);
    }
}