.powerbar-nav-second-level-item {
    // Every second level panel link is contained in a row with
    // cells for the link, the shortcut (if applicable, and the
    // third level indicator
    display: table-row;
    cursor: pointer;
    position: relative;
    /* Edge hack for child absolute positioning */
    transform: scale(1);

    &:hover {
        & > div {
            background-color: $link-hover-bg-color;
        }
    }
    // &:hover::after {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: #{$link-padding * -1};
    //     width: calc(100% + #{$link-padding * 2});
    //     height: 100%;
    //     border-radius: 4px;
    //     background-color: $link-hover-bg-color;
    //     z-index: -1;
    // }

    &__col {
        display: table-cell;

        &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding: 0 0 0 $link-padding; // Handles the rounding at the start of the row
        }
        &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            min-width: $link-padding; // To allow for rounded corners on items with no >
        }
    }

    &__link {
        white-space: nowrap;
        color: $link-text-color;
        font-family: $barlow-font-stack;
        font-style: normal;
        font-weight: normal;
        font-size: toRem(15);
        line-height: toRem(24);
        list-style-type: none;
        text-decoration: none;
        display: block;
    }

    &__shortcut {
        margin: 0;
        text-align: right;
        padding-left: $link-padding;
        color: color(int-teal-tint-40);
        font-family: $barlow-font-stack;
        font-style: normal;
        font-weight: normal;
        font-size: toRem(15);
    }

    &__has-third-level {
        width: 6px; // Width of the chevron bg img
        height: 12px;
        display: block;
        padding-left: $link-padding * 2 + 6px; // Add extra to the right for hover state
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.636963 1.41431L5.32715 5.9868L0.636963 10.5594' stroke='%23FFFFFF' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: $link-padding + 6px; // Position the > with the point matching the group break line
        background-position-y: center;
        transition: transform 0.15s linear;
        z-index: 2;
    }
}
