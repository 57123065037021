.ax-whats-new-panel {

    .whatsnew-backdrop {
        background-color: rgba(0,0,0,.5);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 3
    }

    &__heading {
        color: var(--int-white);
        font-family: $barlow-font-stack;
        font-size: 1.5em;
        line-height: 2em;
        font-weight: 400;
        margin: 0;
    }

    .whats-new-list {
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
    }
    
    .whats-new-list .item iframe {
    width: 330px;
    height: 200px;
    overflow: none;
    
    }     
}


