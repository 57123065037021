@import './font-faces/font-faces';

.ax-default-font,
.ax-default-font * {
    font-family: $barlow-font-stack;
}

.ax-typography {
    // TODO resets?
    font-family: $barlow-font-stack;
    font-size: toRem(16);
    font-variant-ligatures: no-common-ligatures;

    // Headings
    h1 {
        font-size: toRem(24);
        line-height: toRem(32);
        font-family: $barlow-font-stack;
        font-weight: 400;
        font-style: normal;
    }

    h2 {
        font-size: toRem(18);
        line-height: toRem(24);
        font-family: $barlow-font-stack;
        font-weight: 600;
        font-style: normal;
    }

    h3 {
        font-size: toRem(16);
        line-height: toRem(24);
        font-family: $barlow-font-stack;
        font-weight: 600;
        font-style: normal;
    }

    h4 {
        font-size: toRem(14);
        line-height: toRem(24);
        font-family: $barlow-font-stack;
        font-weight: 600;
        font-style: normal;
    }

    h5 {
        font-size: toRem(12);
        line-height: toRem(16);
        font-family: $barlow-font-stack;
        font-weight: 600;
        font-style: normal;
    }

    // Body text
    p {
        font-size: toRem(14);
        line-height: toRem(18);
        font-family: $barlow-font-stack;
        font-weight: 400;
        font-style: normal;

        &--sm {}
        &--md {}
        &--lg {}
        &--lead {}
    }

    span {
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
        font-weight: inherit;
        font-style: inherit;
    }

    // bold
    // strong
    b, strong {
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
        font-weight: 600;
        font-style: inherit;
    }

    // italic
    // emphasis
    i, em {
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
        font-weight: inherit;
        font-style: italic;
    }

    // links
    a {
        color: color(class-teal);
    }

    // Lists
    ul {}

    ol {}

    // Pre, code
    code,
    code span {
        font-family: $monospace-font-stack;
        background-color: #f2f2f2;
        padding: 3px 5px;
        border-radius: 5px;
    }

    // blockquote
    // HR

}
