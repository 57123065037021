// Add this class to <body> to reset page globally
.ax-reset-global {
    @include reset-global;
}

.ax-reset {
    @include reset(general);
}

// For bootstrap overrides see src/styles/scss/vendor/_bootstrap-config.scss

.ax-utilities {
    @import "~bootstrap/scss/utilities";

    @import "~bootstrap/scss/helpers";


    // Add extra items to utilities map before generating classes
    // See https://getbootstrap.com/docs/5.0/utilities/api/#modify-utilities for explanation
    $utilities: map-merge(
        $utilities,
        (
            "font-weight": map-merge(
                map-get($utilities, "font-weight"), (
                    values: map-merge(
                        map-get(map-get($utilities, "font-weight"), "values"),
                        (
                            thin: $font-weight-thin,
                            medium: $font-weight-medium,
                            semibold: $font-weight-semibold,
                            black: $font-weight-black
                        ),
                    ),
                ),
            ),
            "font-size": map-merge(
                map-get($utilities, "font-size"), (
                    values: map-merge(
                        map-get(map-get($utilities, "font-size"), "values"),
                        (
                            heading-1: $h1-font-size,
                            heading-2: $h2-font-size,
                            heading-3: $h3-font-size,
                            heading-4: $h4-font-size,
                            heading-5: $h5-font-size,
                            heading-6: $h6-font-size,
                            body: $font-size-base,
                        ),
                    ),
                ),
            ),
            "line-height": map-merge(
                map-get($utilities, "line-height"), (
                    values: map-merge(
                        map-get(map-get($utilities, "line-height"), "values"),
                        (
                            heading-1: 32px,
                            heading-2: 24px,
                            heading-3: 24px,
                            heading-4: 24px,
                            body: 18px,
                        ),
                    ),
                ),
            ),
        )
    );

    // This comes after custom $utilities map adjustments
    @import "~bootstrap/scss/utilities/api";
}
