.powerbar-nav-events-panel {
    position: absolute;
    top: -12px;
    left: calc(100% + #{$link-padding});

    background: $menu-background;
    list-style-type: none;
    border-radius: 0 8px 8px 8px;
    padding: 12px #{$link-padding} 22px;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
    width: auto;

    &__item {
        padding: 0 $link-padding;
        border-radius: $hover-border-radius;

        &:hover {
            background-color: $link-hover-bg-color;
        }
    }

    &__link {
        color: $link-text-color;
        text-decoration: none;
        font-family: $barlow-font-stack;
        white-space: nowrap;
        font-size: toRem(15);
        line-height: toRem(24);
        display: block;
    }
}
