@import './components/_icon-button/icon-button.scss';
@import './components/_sliding-panel/sliding-panel.scss';
@import './components/_results-panel/results-panel.scss';

@import './components/AxSearchbar/AxSearchbar.scss';
@import './components/PowerbarNav/PowerbarNav.scss';
@import './components/AxSidebar/AxSidebar.scss';
@import './components/SettingsPanel/SettingsPanel.scss';
@import './components/WhatsNewPanel/WhatsNewPanel.scss';
@import './components/HelpPanel/HelpPanel.scss';

.ax-powerbar {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1150;
}
