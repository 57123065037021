.ax-icon-chip {
    display: grid;
    border-radius: toRem(4);
    grid-template-columns: 30px 1fr;
    grid-template-rows: 1fr min-content;
    column-gap: 5px;
    padding: toRem(5) toRem(12) toRem(5) toRem(5);
    box-sizing: border-box;
    font-family: $barlow-font-stack;
    background: color(int-white);
    max-width: auto;
    width: max-content;

    &:hover {
        background: color(int-teal-tint-20);
    }

    &__icon {
        // TODO optional colours
        background: color(class-teal);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: toRem(4);
        grid-row-start: 1;
        grid-row-end: last-line;
        width: toRem(30);
        height: toRem(30);
        font-size: toRem(18);
        color: white;
        font-weight: 600;
        text-transform: uppercase;
        font-family: $barlow-font-stack;
    }

    &__label {
        grid-column-start: 2;
        font-size: toRem(14);
        line-height: 1.15;
        font-weight: 600;
        color: color(int-dark-grey);
        font-family: $barlow-font-stack;
        white-space: normal;

        * {
            // In case a <p> tag gets used in here
            padding: 0;
            margin: 0;
        }
    }

    &__sublabel {
        grid-column-start: 2;
        font-size: toRem(12);
        line-height: toRem(14);
        font-weight: 400;
        font-family: $barlow-font-stack;
        color: color(int-dark-grey);

        * {
            // In case a <p> tag gets used in here
            padding: 0;
            margin: 0;
        }
    }

    // TODO alternate background for on white?
    // On white variant
    &--on-white {
        background-color: color(int-warm-grey-tint-5);
        box-shadow: 0 0 0 1px color(int-warm-grey-tint-10);
    }

    // Expand on hover variant
    &--expand-on-click {
        position: relative;

        &, &:hover {
            background: transparent;
        }

        @mixin expandTransition() {
            -webkit-transition: max-width 0.1s ease-out, width 0.1s ease-out, max-height 0.1s ease-out, height 0.1s ease-out;
            -moz-transition: max-width 0.1s ease-out, width 0.1s ease-out, max-height 0.1s ease-out, height 0.1s ease-out;
            -o-transition: max-width 0.1s ease-out, width 0.1s ease-out, max-height 0.1s ease-out, height 0.1s ease-out;
            transition: max-width 0.1s ease-out, width 0.1s ease-out, max-height 0.1s ease-out, height 0.1s ease-out;
        }

        .ax-icon-chip__label,
        .ax-icon-chip__sublabel {
            text-overflow: ellipsis;
            @include expandTransition();
        }

        .ax-icon-chip__backdrop {
            content: '';
            background-color: rgba(255, 255, 255, 1);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: inherit;
            overflow: hidden;
            box-sizing: border-box;
            display: grid;
            grid-template-columns: 30px 1fr;
            grid-template-rows: 1fr min-content;
            column-gap: 5px;
            padding: toRem(5) toRem(12) toRem(5) toRem(5);

            @include expandTransition();
        }

        &:hover .ax-icon-chip__backdrop {
            background-color: color(int-teal-tint-20);
        }

        &.ax-icon-chip--on-white .ax-icon-chip__backdrop {
            background-color: color(int-warm-grey-tint-5);
            box-shadow: 0 0 0 1px color(int-warm-grey-tint-10);
        }
    }
}