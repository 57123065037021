.ax-overlay {
    $overlayTransparency: rgba(0, 0, 0, 0.5);

    background: $overlayTransparency;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
}

.ax-modal {
    // TODO: remove following 2 lines when global default font-family can be set.
    font-family: $barlow-font-stack;
    * { font-family: $barlow-font-stack; }

    // TODO color vars
    $modalBarColor: #e5f2f4;
    $modalBodyColor: color(int-white);
    $modalDropShadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
    $LRPadding: 24px;

    display: none;
    text-align: initial;
    position: fixed;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    background: white;
    flex-direction: column;
    box-shadow: $modalDropShadow;
    /* z-index is set in js file */

    &--open {
        display: flex;
    }

    &--open + .ax-overlay {
        display: block;
    }

    &__header-container {
        background: $modalBarColor;
        flex: 0 0 52px;
        display: flex;
        align-items: center;
        padding: 0 $LRPadding;
    }

    &__header-text {
        flex: 1 0 50%;
        margin: 0;
        font-family: $roboto-font-stack;
        font-size: toRem(20);
        font-weight: 400;
        display: flex;
        color: var(--int-dark-grey);
        // TODO wrapping or ellipsis?
    }

    &__header-icons {
        flex: 0 0 auto;
        display: flex;
        transform: translateX(calc(#{$LRPadding} - 6px));
    }

    &__body {
        background: $modalBodyColor;
        flex: 1 1 auto;
        overflow-y: auto;
        padding: 33px $LRPadding 64px;
        font-family: $roboto-font-stack;

        &--no-padding {
            padding: 0;
        }
    }

    &__iframe {
        width: 100%;
        border: 0;
    }

    &__footer {
        background: $modalBarColor;
        flex: 0 0 60px;
        padding: 0 $LRPadding;
        display: flex;
        align-items: center;

        & .ax-btn--secondary {
            /* Default footer is same color as secondary button, so force 'on-color' variant */
            @extend .ax-btn--secondary-on-color;
        }
    }

    &__footer-left {
        flex: 1 1 auto;
        & button {
            margin-right: 16px;
        }
        /* Shift over link button if first in LHS */
        & .ax-btn--link:first-of-type,
        & .ax-btn--tertiary:first-of-type {
            margin-left: -15px;
        }
    }

    &__footer-right {
        flex: 0 0 auto;
        & button,
        & button.ax-btn {
            margin-left: 16px;
        }
    }

    &__function-button {
        border: none;
        width: 40px;
        height: 40px;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;

        &:hover,
        &:active {
            background: rgba(#007a91, 0.15);
        }

        &:focus,
        &:focus-within,
        &:focus-visible {
            border: 1.5px solid #007a91;
            outline: none;
        }
    }

    /* Size variants */
    &--normal {
        width: 605px;
        max-width: 90%;
        max-height: calc(100% - #{64 * 2}px);
    }
    &--window {
        width: calc(100% - #{48 * 2}px);
        min-width: calc(100% - #{48 * 2}px);
        max-height: calc(100% - #{64 * 2}px);
        height: auto;
    }

}
