@font-face {
    font-family: 'Roboto Mono';
    src: url('#{$monospace-fonts-dir}RobotoMono-Thin.ttf') format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('#{$monospace-fonts-dir}RobotoMono-ThinItalic.ttf') format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('#{$monospace-fonts-dir}RobotoMono-Light.ttf') format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('#{$monospace-fonts-dir}RobotoMono-LightItalic.ttf') format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('#{$monospace-fonts-dir}RobotoMono-Regular.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('#{$monospace-fonts-dir}RobotoMono-Italic.ttf') format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('#{$monospace-fonts-dir}RobotoMono-Medium.ttf') format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('#{$monospace-fonts-dir}RobotoMono-MediumItalic.ttf') format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('#{$monospace-fonts-dir}RobotoMono-SemiBold.ttf') format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('#{$monospace-fonts-dir}RobotoMono-SemiBold.ttf') format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('#{$monospace-fonts-dir}RobotoMono-Bold.ttf') format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('#{$monospace-fonts-dir}RobotoMono-BoldItalic.ttf') format("truetype");
    font-weight: 700;
    font-style: italic;
}
