.ax-tab-carousel {
    $tabsDropShadowPrev: 2px 1px 2px rgba(0, 0, 0, 0.25);
    $tabsDropShadowNext: -2px 1px 2px rgba(0, 0, 0, 0.25);
    position: relative;
    font-family: $barlow-font-stack;
    overflow: hidden;

    &__view {
        position: relative;
        right: 0;
        left: 0;
        top: 0;
        display: flex;
        counter-reset: item;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        transition: transform .3s ease;
        -webkit-transition: transform .3s ease;
        -moz-transition: transform .3s ease;
        -o-transition: transform .3s ease;
        transform: translateX(0px);

        label {
            font-family: $barlow-font-stack;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: var(--int-warm-grey);
            position: relative;
            display: inline-block;
            padding: 12px 32px;
            border-bottom: 0;
            cursor: pointer;
            font-weight: 600;
            background-color: var(--int-teal-tint-20);
            border-radius: 4px 4px 0px 0px;
            margin-right: 6px;
            border: 1px solid transparent;
            white-space: nowrap;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid transparent;

            &:hover {
                background-color: var(--int-teal-tint-40);
            }
            &:focus {
                border: 1px solid var(--int-teal);
                outline: none;
            }
        }

        .ax-tab-pill {
            padding: 12px;
            width: auto;
            
            .ax-pill {
                font-weight: 400;
                padding: 0;
                margin-left: 54px;
            }
        }

        .ax-tab-pill-none {
            padding: 12px;
            padding-right: 80px;
        }

        input[type="radio"] {
            position: absolute;
            left: -200vw;

            &:checked + label {
                background-color: var(--int-white);
                color: var(--int-dark-grey);
            }
            &:checked:focus + label {
                outline: none;
            }
            &:disabled + label {
                background-color: var(--int-warm-grey-tint-10);
                box-shadow: none;
                cursor: not-allowed;
                border: 1px solid transparent;
            }
        }
    }

    &__btn {
        position: absolute;
        background-color: var(--int-teal);
        width: 30px;
        height: 40px;
        padding: 2.5px 0;
        background-size: 8px;
        background-repeat: no-repeat;
        z-index: 2;
        cursor: pointer;
        opacity: 1;
        transition: opacity .3s ease;
        -webkit-transition: opacity .3s ease;
        -moz-transition: opacity .3s ease;
        -o-transition: opacity .3s ease;

        &:hover {
            background-color: var(--int-teal-shade-75);
        }

        &:active {
            box-shadow: none;
        }
    }

    &__prev {
        top: 0;
        left: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.87031 0.127594C6.6974 -0.0425314 6.41705 -0.0425314 6.24414 0.127594L0.86848 5.41654C0.695566 5.58667 0.695566 5.8625 0.86848 6.03262L6.24414 11.3216C6.41705 11.4917 6.6974 11.4917 6.87031 11.3216C7.04323 11.1514 7.04323 10.8756 6.87031 10.7055L1.80775 5.72458L6.87031 0.743673C7.04323 0.573548 7.04323 0.29772 6.87031 0.127594Z' fill='white'/%3E%3C/svg%3E");
        background-position: right 12px top 16px;
        box-shadow: $tabsDropShadowPrev;
    }

    &__next {
        top: 0;
        right: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.129686 0.127594C0.3026 -0.0425314 0.58295 -0.0425314 0.755865 0.127594L6.13152 5.41654C6.30443 5.58667 6.30443 5.8625 6.13152 6.03262L0.755865 11.3216C0.58295 11.4917 0.3026 11.4917 0.129686 11.3216C-0.0432286 11.1514 -0.0432286 10.8756 0.129686 10.7055L5.19225 5.72458L0.129686 0.743673C-0.0432286 0.573548 -0.0432286 0.29772 0.129686 0.127594Z' fill='white'/%3E%3C/svg%3E");
        background-position: right 9px top 16px;
        box-shadow: $tabsDropShadowNext;
    }

    .ax-tab-panels {
        width: 100%;
        color: var(--int-dark-grey);
        left: 0;
        background-color: var(--int-white);
        height: 80vh;

        .ax-tab-panel {
            display: none;
        }
        .ax-tab-panel {
            margin-top: -1px;
            overflow: scroll;
            margin: 32px 32px 32px 0;
            height: inherit;
            width: inherit;
        }
    }
    
    &__default {
        input[type="radio"] {
            position: absolute;
            left: -200vw;
    
            &:checked + label {
                border: 1px solid transparent;
                border-bottom: 1px solid var(--int-white);
            }
        }
    }
    &__white {
        position: relative;

        .ax-tab-carousel__view {

            label {
                border: 1px solid transparent;
                margin-bottom: 1px;
    
                &:focus {
                    border-bottom: none;
                }
            }
            input[type="radio"] {
                position: absolute;
                left: -200vw;
    
                &:checked + label {
                    border: 1px solid var(--int-teal);
                    margin-bottom: -1px;
                    border-bottom: 1px solid var(--int-white);
                }
            }
        }
        .ax-tab-panels {
            width: inherit;
            border: 1px solid var(--int-teal);
            height: 80vh;
            margin-top: -1px;
            border-radius: 0 4px 4px 4px;
    
            .ax-tab-panel {
                overflow: scroll;
                margin: 32px;
                height: inherit;
                width: inherit;
            }
        }
    }
}

.ax-tab-carousel .ax-tab-carousel__view > input:first-child:checked ~ .ax-tab-panels > .ax-tab-panel:first-child,
.ax-tab-carousel .ax-tab-carousel__view > input:nth-child(3):checked ~ .ax-tab-panels > .ax-tab-panel:nth-child(2),
.ax-tab-carousel .ax-tab-carousel__view > input:nth-child(5):checked ~ .ax-tab-panels > .ax-tab-panel:nth-child(3),
.ax-tab-carousel .ax-tab-carousel__view > input:nth-child(7):checked ~ .ax-tab-panels > .ax-tab-panel:nth-child(4),
.ax-tab-carousel .ax-tab-carousel__view > input:nth-child(9):checked ~ .ax-tab-panels > .ax-tab-panel:nth-child(5),
.ax-tab-carousel .ax-tab-carousel__view > input:nth-child(11):checked ~ .ax-tab-panels > .ax-tab-panel:nth-child(6) {
    display: block;
}