.powerbar-nav-second-level-panel {
    position: absolute;
    top: calc(100% + #{$link-padding});
    left: #{$link-padding * -1};
    background: $menu-background;
    padding: 16px #{$link-padding};
    border-radius: 0px 0px 8px 8px;
    display: flex;
    box-shadow: -4px 4px 8px -4px rgba(0, 0, 0, 0.25), 4px 4px 8px -4px rgba(0, 0, 0, 0.25);

    &__column {
        display: table;
        border-collapse: collapse;
        height: 0;
        margin-top: 0;

        &:not(:last-child) {
            margin-right: calc(42px - #{$link-padding * 2});
        }
    }

    &__break-row {
        display: table-row;
    }

    &__group-break {
        padding: 0;

        hr {
            margin: 8px $link-padding;
            height: 0;
            border: 0;
            border-top: 1px solid color(int-teal-tint-40);
        }
    }
}