@font-face {
    font-family: roboto;
    src: url('#{$roboto-fonts-dir}Roboto-Thin.ttf') format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: roboto;
    src: url('#{$roboto-fonts-dir}Roboto-ThinItalic.ttf') format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: roboto;
    src: url('#{$roboto-fonts-dir}Roboto-Light.ttf') format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: roboto;
    src: url('#{$roboto-fonts-dir}Roboto-LightItalic.ttf') format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: roboto;
    src: url('#{$roboto-fonts-dir}Roboto-Regular.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: roboto;
    src: url('#{$roboto-fonts-dir}Roboto-Italic.ttf') format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: roboto;
    src: url('#{$roboto-fonts-dir}Roboto-Medium.ttf') format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: roboto;
    src: url('#{$roboto-fonts-dir}Roboto-MediumItalic.ttf') format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: roboto;
    src: url('#{$roboto-fonts-dir}Roboto-Black.ttf') format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: roboto;
    src: url('#{$roboto-fonts-dir}Roboto-BlackItalic.ttf') format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: roboto;
    src: url('#{$roboto-fonts-dir}Roboto-Bold.ttf') format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: roboto;
    src: url('#{$roboto-fonts-dir}Roboto-BoldItalic.ttf') format("truetype");
    font-weight: 700;
    font-style: italic;
}
