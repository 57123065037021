.powerbar-nav-shortcuts-bar {
    display: flex;

    &__label {
        font-family: $barlow-font-stack;
        font-size: 12px;
        color: var(--int-white);
        font-weight: 500;
        margin-right: 8px;
    }
}




    // &__action-icon {
    //     @include reset(button);
    //     margin-left: 16px;
    //     display: flex;
    //     align-items: center;
    //     position: relative;
    //     width: 20px;

    //     & svg,
    //     &-svg {
    //         fill: #888888;
    //         &:hover {
    //             fill: color(class-spearmint);
    //         }
    //     }

    //     &--active svg,
    //     &--active.__action-icon--svg {
    //         fill: color(class-spearmint);
    //     }
    // }