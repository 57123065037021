.ax-input-group {
    // for inputs with buttons or pieces of text, like a search field
    // TODO File input (with button)
    // TODO Clean up focus states when multiple elements

    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;

    // specific item overrides
    & .ax-btn {
        box-shadow: none;
    }

    & .ax-btn--icon,
    & [class^='ax-icon--'] {
        background-color: color(int-warm-grey-tint-10);
        border:  1px solid color(input-border);

        &:hover {
            background-color: color(int-teal-tint-20);
        }

        &:focus,
        &:focus:not(:focus-visible),
        &:focus-visible {
            border: 1px solid color(int-teal);
            box-shadow: inset 0 0 0 1px color(int-teal); // add extra border without messing up height

            &::before {
                display: none;
            }
        }

    }

    &__text {
        display: flex; align-items: center;
        background: color(int-warm-grey-tint-10);
        border: 1px solid color(input-border);
        padding: 0 toRem(8);
        font-family: $barlow-font-stack;
    }

    & > * {
        box-sizing: border-box;
        height: auto;
    }

    // Un-round any inner corners
    & > :not(:last-child),
    & > :not(:last-child)::before,
    & > :not(:last-child)::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    // if a leading icon is first, don't un-round corners of next item
    & > :not(:first-child),
    & > :not(:first-child)::before,
    & > :not(:first-child)::after {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}