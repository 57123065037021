.ax-validation-feedback {
    color: color(int-warm-grey);
    font-family: $barlow-font-stack;
    font-size: toRem(14);
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-top: toRem(10);

    &.success {
        color: color(status-green);
        &::before {
            content: '';
            width: 20px;
            height: 20px;
            @include backgroundIcon(status-tick, color(status-green));
            background-size: contain;
            margin-right: toRem(12);
        }
    }

    &.error {
        color: color(status-red);
        &::before {
            content: '';
            width: 20px;
            height: 20px;
            @include backgroundIcon(status-exclamation, color(status-red));
            background-size: contain;
            margin-right: toRem(12);
        }
    }
}

.ax-input {
    &[aria-invalid="true"],
    &.is-invalid {
        border-color: color(status-red);
        box-shadow: inset 0 0 3px -2px var(--status-red);
    }
}

.ax-radio[aria-invalid="true"],
.ax-radio.is-invalid {

    &,
    &:focus,
    &:focus-visible,
    &:hover:focus,
    &:hover:focus-visible {
        & + label {
            color: color(status-red);

            &::before {
                border-color: color(status-red);
            }
            &::after {
                box-shadow: 0 0 0 5.5px inset darkenOnHover(status-red);
            }
        }
    }

    &:checked + label {
        &::after {
            box-shadow: 0 0 0 5.5px inset color(status-red);
        }
    }
}

.ax-checkbox[aria-invalid="true"],
.ax-checkbox.is-invalid {
    &,
    &:focus,
    &:focus-visible {
        & + label {
            color: color(status-red);

            &::before
            {
                border-color: color(status-red);
            }
        }
    }

    &:checked + label::before,
    &:checked:focus + label::before,
    &:checked:focus-visible + label::before {
        background: color(status-red);
    }
}

.ax-fieldset[aria-invalid="true"],
.ax-fieldset.is-invalid {
    legend {
        color: color(status-red)
    }
}

// Not for buttons?
// Radios and checkboxes

// TODO validated

// input classes is-valid is-invalid

// Fieldset validation
// Wrapper validation

// Message container
// Icon variants


// Label positions


// Was validated on container for success icon