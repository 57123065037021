.ax-searchbar {
    background: var(--class-deep-sea);
    padding: 8px;
    position: relative;
    z-index: 5;

    display: grid;
    grid-template-columns: 1fr auto 1fr;

    &__left-container {
        display: flex;
        align-items: center;
    }

    &__center-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    &__right-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__search {
        width: 450px;

        & .ax-input {
            border-color: transparent;
            height: 40px;

            &::placeholder {
                color: #979797;
            }

            &:focus,
            &:focus-visible,
            &:focus-within {
                border-color: transparent;
                box-shadow: none;
            }

        }

    }

    &__toggle-container {
        display: flex;
        padding-left: 30px;

        .ax-toggle > .ax-toggle__label-text{
            color: var(--int-white);
        }

        .ax-toggle__checkbox:checked + .ax-toggle__slider {
            background-color: #3C647E;
        }
    }

    &__logo-container {
        margin-right: 20px;
        margin-left: 10px;
    }

    &__action-icons {
        flex: 0 0 auto;
        display: flex;

        & > .ax-searchbar__icon-link:not(:first-child) {
            margin-left: 6px;
        }
    }
}
