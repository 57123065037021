.ax-radio {
    position: relative;

    // Hide the real radios
    &:checked,
    &:not(:checked) {
        position: absolute;
        left: -9999px;
        opacity: 0;
    }

    & + label, & + span {
        font-family: $barlow-font-stack;
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        font-size: toRem(14);
        height: 20px;
        display: inline-block;
        color: color(int-warm-grey);
        display: flex;
        align-items: center;
        line-height: 1;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            z-index: 2;
            box-sizing: border-box;
        }

        &::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            top: 0;
            left: 0;
            box-shadow: 0 0 0 5.5px inset color(class-teal);
            border-radius: 50%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            z-index: 3;
        }

        // Required
        &[required],
        &.required,
        &:required {
            &::after {
                content: '';
            }
        }
    }

    &:not(:checked) {
        & + label::before, & + span::before {
            border: 1px solid color(input-border);
            background-color: #fff;
        }

        & + label::after, & + span::after {
            opacity: 0;
        }
    }

    &:checked {
        & + label::before, & + span::before {
            border: 1px solid transparent;
            background-color: color(class-teal);
        }

        & + label::after, & + span::after {
            background-color: color(int-white);
            opacity: 1;
        }
    }

    // Hover and focus states
    // https://www.tpgi.com/focus-visible-and-backwards-compatibility/
    &:focus + label::before,
    &:hover + label::before,
    &:focus + span::before,
    &:hover + span::before {
        border-color: darkenOnHover(class-teal);
        box-shadow: 2px 2px 4px rgba(0,0,0,0.1);
    }
    &:checked:focus + label::after,
    &:checked:focus:hover + label::after,
    &:checked:hover + label::after,
    &:checked:focus + span::after,
    &:checked:focus:hover + span::after,
    &:checked:hover + span::after {
        box-shadow: 0 0 0 5.5px inset darkenOnHover(class-teal);
    }
    &:focus:not(:focus-visible) + label::before, 
    &:focus:not(:focus-visible) + span::before {
        border-color: color(class-teal);
        box-shadow: none;
    }
    &:checked:focus:not(:focus-visible) + label::before, 
    &:checked:focus:not(:focus-visible) + span::before {
        background-color: color(class-teal);
    }
    &:focus-visible + label::before,
    &:focus-visible + span::before {
        border-color: darkenOnHover(class-teal);
        box-shadow: 2px 2px 4px rgba(0,0,0,0.1);
    }
    &:checked:focus-visible + label::before,
    &:checked:focus:hover + label::before,
    &:checked:focus-visible + span::before,
    &:checked:focus:hover + span::before {
        background-color: darkenOnHover(class-teal);
    }

    &[disabled],
    &:disabled,
    &.disabled {
        & + label, & + span {
            pointer-events: none;
            cursor: not-allowed;
            color: color(disabled-text);
        }

        & + label::before, & + span::before {
            background-color: color(int-warm-grey-tint-5);
        }

        &:checked + label::before, &:checked + span::before {
            background-color: color(int-teal-tint-10);
        }

        &:checked + label::after, &:checked + span::after {
            background-color: color(int-warm-grey-tint-50);
            box-shadow: 0 0 0 5.5px inset color(int-teal-tint-10);
        }
    }

}