$link-padding: 14px;
$link-text-color: var(--int-white);
$hover-border-radius: 4px;
$menu-background: var(--int-washed-denim);
$border-color: #d9d9d9;
$link-hover-bg-color: rgba(255, 255, 255, 0.2);

@import './TopLevelMenuItem',
'./SecondLevelMenuPanel',
'./SecondLevelMenuItem',
'./EventsPanel',
'./ShortcutsBar';

.powerbar-nav {
    display: flex;
    align-items: center;
    min-height: 60px;
    background: $menu-background;
    padding: 0 8px;
    position: relative;
    z-index: 2;

    &__info-container {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        padding: 8px 0;
        margin-right: 20px;

        & > *:not(:last-child) {
            margin-right: 8px;
        }
    }

    &__links-container {
        flex: 1 0 auto;
        display: flex;
    }

    &__shortcuts-container {
        flex: 0 0 auto;
        display: flex;
    }

    &__nav {
        display: flex;
        align-items: stretch;
        position: relative;
    }

}
