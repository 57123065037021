.ax-sidebar {
    position: relative;

    .sidebar-backdrop {
        background-color: rgba(0,0,0,.5);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 3
    }

    &__panel {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    &__top {
        flex : 0 0 auto;
    }

    &__logo-container {
        display: flex;
        box-sizing: border-box;
        width: calc(100% + 24px);
        margin-left: -12px;
        position: relative;

        &:not(:first-child) {
            &::before{
                border-top : 1px solid var(--int-teal-tint-40);
                content:'';
                width: 100%;
                position: absolute;
                top: 0px;
                width: calc(100% - 4px);
                margin-left: 2px;
                left: 0px;
            }
        }

        &:last-child {
            &::after {
                border-top : 1px solid var(--int-teal-tint-40);
                content:'';
                width: 100%;
                position: absolute;
                bottom: 0px;
                width: calc(100% - 4px);
                margin-left: 2px;
                left: 0px;
            }
        }

        &:hover {
            .ax-sidebar__logo-wrapper {
                background: #d9ebef;
                filter: none;
            }

            &::before,
            &::after {
                border-color: transparent;
            }

            & + div {
                &::before,
                &::before {
                    border-color: transparent;
                }
            }
        }
    }

    &__logo-wrapper {
        filter: brightness(0) invert(1);
        border-radius: 4px;
        padding: 0 12px;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;

        a {
            flex: 1;
        }
    }

    &__middle {
        flex: 1 0 auto;
    }

    &__bottom {
        flex: 0 0 auto;
        font-family: $barlow-font-stack;

        a {
            color: #FFFF;
            text-decoration: none;
        }

        .title-container {
            font-size: 12px;
        }

        .link-container {
            font-size: 15px;
            padding: 5px 12px;
            margin-left: -12px;
            border-radius: 5px;
            width: calc(100% + 24px);
            box-sizing: border-box;

            a {
                display: block;
            }

            &:hover {
                background: rgb(255,255,255,.2);
            }
        }

        .seperation-line {
           border: none;
           border-bottom: 1px solid  var(--int-teal-tint-40) ;
           width: calc(100% + 18px);
           margin-left: -9px;
           box-sizing: border-box;
        }
    }

    &__links-group-heading {
        color: #FFFF;
        font-weight: 600;
        font-family: $barlow-font-stack;
        font-size: toRem(12);
    }

}
