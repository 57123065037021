.ax-pill {
        padding: 5px;
        font-family: roboto;
        font-size: 0.75em;
    
    &--low-attention {
        background-color: #458248;
        border-radius: 10px;
        color: white;
        padding: 4px 6px 4px 6px;
    }
    &--high-attention {
        background-color: #CF1919;
        border-radius: 10px;
        color: white;
        padding: 4px 6px 4px 6px;
    }
    &--medium-attention {
        background-color: #FF9533;
        border-radius: 10px;
        color: #2B313A;
        padding: 4px 6px 4px 6px;
    }
}
