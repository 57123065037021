.ax-label {
    font-family: $barlow-font-stack;
    font-size: toRem(14);
    line-height: toRem(17);
    font-weight: 600;
    color: color(int-warm-grey);
    margin: toRem(7) 0;
    position: relative;
    display: block;

    &--inline {
        display: inline-block;
    }

    &.optional::after {
        content: ' (optional)';
        font-weight: 400;
        font-style: italic;
    }

    // Required
    &[required],
    &.required,
    &:required {
        &::after {
            content: ' (required)';
            font-weight: 400;
            font-style: italic;
        }
    }

    // If input wrapped in label:
    & > input {
        display: block;
        width: 100%;
        margin-top: toRem(7);
    }
}